import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { SetNotification } from "../../../../store/notification/notification.action";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  Typography,
  Grid,
  Box,
  ClickAwayListener,
  Chip,
} from "@material-ui/core";
import {
  CreateOrder,
  LoadCouponCodeById,
  LoadPricingRuleById,
  SetCustomRef,
} from "../../../../store/checkout/checkout.action";
import { loadCouponCodes } from "../../../../store/client/client.action";
import { useTranslation } from "react-i18next";
import { formatDate, symbol } from "../../../../helpers/utils";
import AlertModal from "../../../../components/common/alertModal";
import Keyboard from "react-simple-keyboard";
import french from "simple-keyboard-layouts/build/layouts/french";
import { UpdateAppliedLoyCard } from "../../../../store/cart/cart.action";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 8,
    height: "22%",
  },
  virtualKeyboard: {
    position: "fixed",
    width: "80%",
    boxShadow: theme.shadows[5],
    borderRadius: 10,
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -105%)",
  },
  checkoutResume: {
    fontSize: 18,
    fontWeight: "bolder",
    align: "left",
    display: "block",
    textTransform: "uppercase",
  },
  button: {
    fontSize: 32,
    fontWeight: "bold",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.productsTable.button,
    color: theme.palette.productsTable.buttonColor,
    "&:hover": {
      backgroundColor: theme.palette.productsTable.button,
    },
  },
  modalPaper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  totalPaper: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const TotalPrice = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const appliedLoyaltyCard = useSelector(
    (state) => state.Cart.appliedLoyaltyCard
  );
  const selectedSalesPerson = useSelector(
    (state) => state.Checkout.selectedSalesPerson
  );
  const loyaltyProgramDetails = useSelector(
    (state) => state.Cart.loyaltyProgramDetails
  );
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const customergroupparent = useSelector(
    (state) => state.Configuration.customergroupparent
  );
  const discount = useSelector((state) => state.Checkout.discount);
  const itemDetails = useSelector((state) => state.Checkout.itemDetails);
  const products = useSelector((state) => state.Checkout.products);
  const posOpenedDate = useSelector((state) => state.Login.posOpeningDate);

  const itemgroup = useSelector((state) => state.Configuration.itemgroup);
  const selectedClient = useSelector((state) => state.Checkout.client);
  const defaultClient = useSelector((state) => state.Checkout.defaultClient);
  const selectedCoupon = useSelector((state) => state.Checkout.selectedCoupon);
  const pricingRules = useSelector((state) => state.Checkout.pricingRules);
  const amount = useSelector((state) => state.Checkout.amount);
  const [codeCoupon, setCodeCoupon] = React.useState("");
  const [codeCouponIsvalid, setCodeCouponIsvalid] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const rows = useSelector((state) => state.Checkout.products);
  const currencies = useSelector((state) => state.Client.currencies);
  const client = useSelector((state) => state.Checkout.client);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const taxsales = useSelector((state) => state.Checkout.taxsales);
  const taxsalesvalue =
    taxsales && !isNaN(taxsales?.value) ? parseFloat(taxsales?.value) : 0;
  const { t } = useTranslation(["common", "checkout", "payement"]);
  const [keyboardVisibility, setKeyboardVisibility] = React.useState(false);
  const keyboard = React.useRef();
  const [layout, setLayout] = React.useState("default");
  const [itemPrestationList, setItemPrestationListList] = useState([]);

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user")).token,
  };

  const getItemPrestationsList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "Item",
        fields: ['`tabItem`.`name`', '`tabItem`.`item_code`', '`tabItem`.`prix_de_vente_ht`', '`tabItem`.`standard_rate`'],
        filters: [
          ['Item', 'is_stock_item', '=', 0],
          ['Item', 'item_group', '=', 'Services']
      ],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setItemPrestationListList(data);
        }
      });
  };

  useEffect(() => {
    getItemPrestationsList();
    }
    , []);

  //console.log(itemPrestationList, "itemPrestationList");

  const calculateTaxRate = (item) => {
    const matchingItem = itemPrestationList.find(prestationItem => prestationItem.item_code === item.item_code);
    if (matchingItem) {
      const standardRate = parseFloat(matchingItem.standard_rate);
      const prixDeVenteHT = parseFloat(matchingItem.prix_de_vente_ht);
      if (standardRate && prixDeVenteHT) {
        return ((prixDeVenteHT - standardRate) / standardRate) * 100;
      }
    }
    return 0;
  };

  const calculateTotalTaxAmount = () => {
    return products.reduce((totalTax, product) => {
      const prestationTaxRate = calculateTaxRate(product);
      if (prestationTaxRate > 0) {
        const taxAmount = (product.price_list_rate * product.quantity * prestationTaxRate) / 100;
        return totalTax + taxAmount;
      } else {
        const existingTaxRate = parseFloat(product.item_tax_template?.match(/\d+/g)?.toString().replace(",", ".")) || 0;
        const taxAmount = (product.price_list_rate * product.quantity * existingTaxRate) / 100;
        return totalTax + taxAmount;
      }
    }, 0);
  };

  const totalTaxAmount = calculateTotalTaxAmount();

  const calculateTotalWithTax = () => {
    return products.reduce((total, product) => {
      const prestationTaxRate = calculateTaxRate(product);
      let productTotal = product.price_list_rate * product.quantity;
  
      if (prestationTaxRate > 0) {
        const taxAmount = (productTotal * prestationTaxRate) / 100;
        productTotal += taxAmount;
      } else {
        const existingTaxRate = parseFloat(product.item_tax_template?.match(/\d+/g)?.toString().replace(",", ".")) || 0;
        const taxAmount = (productTotal * existingTaxRate) / 100;
        productTotal += taxAmount;
      }
  
      return total + productTotal;
    }, 0);
  };

  const totalWithTax = calculateTotalWithTax();
  const totalAfterDiscount = totalWithTax - (props.discount * totalWithTax) / 100 + taxsalesvalue;

  const ignorePricingRule = useSelector(
    (state) => state.Checkout.ignorePricingRules
  );

  const HandleKeyPress = (e) => {
    if (e.key === "Enter") {
      dispatch(LoadCouponCodeById(codeCoupon));
      setCodeCoupon("");
    }
  };
  const handleClickCouponCode = (e) => {
    dispatch(LoadCouponCodeById(codeCoupon));
    setCodeCoupon("");
  };
  const validationcodeCoupon = () => {
    var isvalid = false;
    rows.forEach((element) => {
      const myIndex = pricingRules?.items?.findIndex(
        (el) => el.item_code == element.item_code
      );
      if (myIndex > -1) isvalid = true;
    });
    if (codeCouponIsvalid != isvalid) {
      setCodeCouponIsvalid(isvalid);
    }
  };

  useEffect(() => {
    if (pricingRules) validationcodeCoupon();
  }, [rows?.length, pricingRules]);

  const HandlePayment = () => {
    if (selectedClient) {
      if (selectedClient?.customer_name === defaultClient?.customer_name) {
        setOpen(true);
      } else {
        setOpenAlert(true);
      }
    } else {
      dispatch(
        SetNotification({
          code: "error",
          message: "notification:noClientSelected",
        })
      );
    }
    dispatch(loadCouponCodes());
  };

  const UpdateCouponCode = (e) => {
    if (
      e.target.value !== selectedCoupon.name &&
      !selectedCoupon.pricing_rule
    ) {
      setCodeCoupon(e.target.value);
      keyboard.current && keyboard.current.setInput(e.target.value);
    }
    /*  if (selectedCoupon?.name) {
       dispatch(SetCouponCode({}));
       dispatch(SetPricingRules(null));
     } */
  };

  const onChange = (input) => {
    setCodeCoupon(input);
  };

  const [capsLockEnabled, setCapsLockEnabled] = useState(false);

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShift();
    } else if (button === "{bksp}") {
      handleBackspace();
    }
  };

  const handleBackspace = () => {
    const updatedInput = codeCoupon.slice(0, codeCoupon.length - 1); // Remove the character before the cursor
    setCodeCoupon(updatedInput);
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
    setCapsLockEnabled(false);
  };

  const currencySymbol =
    client && client.default_currency
      ? symbol(client.default_currency, currencies)?.symbol
      : symbol(globalDefaults?.default_currency, currencies) &&
        symbol(globalDefaults?.default_currency, currencies)?.symbol;

  useEffect(() => {
    if (selectedCoupon && selectedCoupon.pricing_rule) {
      dispatch(LoadPricingRuleById(selectedCoupon.pricing_rule));
      setCodeCoupon(selectedCoupon.name);
    }
    if (!selectedCoupon?.name) {
      setCodeCoupon("");
    }
  }, [selectedCoupon]);

  const handleCancel = async () => {
    const totalWithTax = calculateTotalWithTax();
    const totalAfterDiscount = totalWithTax - (props.discount * totalWithTax) / 100 + taxsalesvalue
    if (
      appliedLoyaltyCard?.redeem_points_amount &&
      appliedLoyaltyCard?.redeem_points_amount >= props.amount.totalAmount
    ) {
      dispatch(
        UpdateAppliedLoyCard({
          ...appliedLoyaltyCard,
          redeem_points_amount: parseFloat(props.amount.totalAmount),
        })
      );
      var items = {};
      var paymentList = [];
      var taxes = [];
      var salesTeam = [];
      const totalAmount = props.amount.totalAmount
        ? (
            props.amount.totalAmount -
            (discount * props.amount.totalAmount) / 100 +
            taxsalesvalue
          ).toFixed(3)
        : 0;
      const totalAmountPayed = amount.payments
        ? amount.payments.reduce((a, v) => (a = a + parseFloat(v.amount)), 0)
        : 0;
      const rendu =
        props.amount.totalAmount &&
        parseFloat(totalAmountPayed - totalAmount) <= 0
          ? 0
          : parseFloat(totalAmountPayed - totalAmount).toFixed(3);
      amount.payments.forEach((element) => {
        const payData = {
          parentfield: "payments",
          parenttype: "POS Invoice",
          amount: element.amount - rendu,
          mode_of_payment: element.mode_of_payment,
          type: element.type,
          doctype: "Sales Invoice Payment",
          __islocal: 1,
          idx: 1,
        };
        paymentList.push(payData);
      });
      products.forEach((element, index) => {
        const existingItem = itemDetails.find(
          (item) => item.item_code === element.name
        );

        const mainItem = {
          doctype: "POS Invoice Item",
          docstatus: 0,
          __islocal: 1,
          __unsaved: 1,
          parenttype: "POS Invoice",
          parentfield: "items",
          qty: element.quantity,
          item_name: element.item_name,
          item_code: element.name,
          item_group: element.item_group,
          discount_percentage: element?.discount,
          discount_amount: existingItem ? existingItem.discount_amount : 0,
          item_tax_template: element.item_tax_template,
        };

        items = { ...items, [`item${index + 1}`]: mainItem };

        // Handle taxes (unchanged from your original code)
        if (element?.item_tax_template) {
          const tax = {
            account_head: element?.item_tax_template?.substring(
              element?.item_tax_template.indexOf(" ") + 1
            ),
            parentfield: "taxes",
            parenttype: "POS Invoice",
            doctype: "Sales Taxes and Charges",
            charge_type: "On Net Total",
            cost_center: `${companyacounts?.cost_center}`,
            description:
              element?.item_tax_template.slice(
                8,
                element?.item_tax_template.indexOf("%") + 1
              ) +
              " @ " +
              parseFloat(
                element?.item_tax_template
                  .match(/\d+/g)
                  .toString()
                  .replace(",", ".")
              ).toFixed(1),
          };
          taxes = [...taxes, tax];
        }
      });
      const uniqueTaxes = [];
      const unique = taxes.filter((element) => {
        const isDuplicate = uniqueTaxes.includes(element.account_head);
        if (!isDuplicate) {
          uniqueTaxes.push(element.account_head);
          return true;
        }
        return false;
      });

      if (taxsales && JSON.stringify(taxsales) !== "{}") {
        unique.push({
          account_head: taxsales?.name,
          charge_type: "Actual",
          cost_center: `${process.env.REACT_APP_COST_CENTER}`,
          description: taxsales?.account_name,
          doctype: "Sales Taxes and Charges",
          parentfield: "taxes",
          parenttype: "POS Invoice",
          tax_amount: taxsalesvalue,
        });
      }
      const selectedSalesPersonNames = [];

      selectedSalesPerson.length &&
        selectedSalesPerson.map((s) => {
          s.sales_person_name
            ? selectedSalesPersonNames.push(s.sales_person_name)
            : selectedSalesPersonNames.push(s);
        });

      const percentage = (sp) => {
        return (
          +(100 / selectedSalesPersonNames.length) *
          +selectedSalesPersonNames.filter((s, i) => s == sp).length
        );
      };

      const allSalesPerson = [...new Set(selectedSalesPersonNames)];
      const netTotal = totalWithTax - totalTaxAmount - (discount * (totalWithTax - totalTaxAmount)) / 100;
      allSalesPerson.forEach((sp, index) => {
        const salesPerson = {
          allocated_amount: (
            (Math.round(percentage(sp)) / 100) *
            netTotal
          ).toFixed(3),
          allocated_percentage: Math.round(percentage(sp)),
          parentfield: "sales_team",
          parenttype: "POS Invoice",
          sales_person: sp,
          __unsaved: 1,
        };
        salesTeam.push(salesPerson);
      });

      const ttlPerc =
        salesTeam.length != 0
          ? salesTeam.reduce(
              (a, v) => (a = a + parseFloat(v.allocated_percentage)),
              0
            )
          : 100;

      if (ttlPerc != 100 && salesTeam.length != 0) {
        salesTeam.length != 0 &&
          (salesTeam[0].allocated_percentage =
            salesTeam[0].allocated_percentage + (100 - ttlPerc));
      }
      let posData = {
        taxes: unique,
        custom_ref: `POS-INV-REF-${Date.now()}`,
        customer: client ? client.customer_name : "",
        contact_email: client ? client.email_id : "",
        contact_mobile: client ? client.mobile_no : "",
        items: items,
        additional_discount_percentage: parseFloat(discount),
        pos_profile: open?.pos_profile,
        payments: [
          {
            parentfield: "payments",
            parenttype: "POS Invoice",
            amount: 0,
            mode_of_payment: "Cash",
            type: "Cash",
            doctype: "Sales Invoice Payment",
            __islocal: 1,
            idx: 1,
          },
        ],
        change_amount: rendu,
        coupon_code: selectedCoupon ? selectedCoupon.name : null,
        ignore_pricing_rule: !ignorePricingRule || selectedCoupon?.name ? 0 : 1,
        transaction_date: formatDate(posOpenedDate, "YYYY-MM-DD"),
        delivery_date: formatDate(posOpenedDate, "YYYY-MM-DD"),
        posting_date: formatDate(posOpenedDate, "YYYY-MM-DD"),
        sales_team: salesTeam,
        paid_amount: totalAfterDiscount,
      };

      if (
        appliedLoyaltyCard &&
        appliedLoyaltyCard?.redeem_points_amount &&
        amount?.isRedeem
      ) {
        posData = {
          ...posData,
          paid_amount: props.amount.totalAmount,
          loyalty_points: parseInt(
            props.amount.totalAmount / loyaltyProgramDetails?.conversion_factor
          ),
          loyalty_amount: props.amount.totalAmount,
          redeem_loyalty_points: 1,
          loyalty_program: appliedLoyaltyCard?.loyalty_program,
          loyalty_redemption_account: loyaltyProgramDetails?.expense_account,
          loyalty_redemption_cost_center: loyaltyProgramDetails?.cost_center,
        };
      }

      const orderData = {
        data: posData,
        default_company: globalDefaults?.default_company,
        default_currency: companyacounts?.default_currency,
        customerGroupParent: customergroupparent,
        itemGroup: itemgroup,
      };

      await dispatch(CreateOrder(orderData));
      await dispatch(SetCustomRef(orderData.custom_ref));
      history.push("/checkout/payement/2");
    } else {
      history.push("/checkout/payement/1");
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        style={{ margin: 0, width: "100%", height: "100%" }}
      >
        <Grid item xs={6} sm={4} style={{ padding: 0 }}>
          <Button
            disabled={props.amount.totalAmount ? false : true}
            onClick={HandlePayment}
            className={classes.button}
            size={"large"}
            variant="contained"
          >
            {t("common:payer")?.toUpperCase()}
          </Button>
        </Grid>

        <Grid
          item
          xs={6}
          sm={8}
          style={{
            padding: 0,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            item
            spacing={3}
            className={classes.totalPaper}
            style={{
              color: "black",
              // backgroundColor: "#0C2605",
              borderRadius: 10,
              height: "100%",
              marginRight: 0,
              marginLeft: 20,
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <Grid item style={{ padding: 5 }}>
              <Typography
                className={classes.checkoutResume}
                variant="caption"
                display="block"
                align="left"
              >
                H.T
              </Typography>
              <Typography
                className={classes.checkoutResume}
                variant="caption"
                display="block"
                align="left"
              >
                {t("common:tax")}
              </Typography>
            </Grid>

            <Grid item style={{ padding: 5 }}>
              <Typography
                className={classes.checkoutResume}
                variant="caption"
                display="block"
                align="left"
              >
                :{" "}
                {props.amount.totalAmount
                  ? (
                      props.amount.totalAmount -
                      amount.taxAmount -
                      (props.discount *
                        (props.amount.totalAmount - amount.taxAmount)) /
                        100
                    ).toFixed(3)
                  : "00.000"}{" "}
                {currencySymbol}
              </Typography>
              <Typography
                className={classes.checkoutResume}
                variant="caption"
                display="block"
                align="left"
               >
                : {totalTaxAmount ? (totalTaxAmount - (props.discount * totalTaxAmount) / 100).toFixed(3) : "00.000"} {currencySymbol}
              </Typography>
            </Grid>

            <Grid item style={{ padding: 5 }}>
              <Typography
                className={classes.checkoutResume}
                align="left"
                variant="caption"
                display="block"
              >
                {t("common:remise")}
              </Typography>
              <Typography
                style={{ color: "#8EBF24" }}
                className={classes.checkoutResume}
                align="left"
                variant="caption"
                display="block"
              >
                {t("common:total")}
              </Typography>
            </Grid>

            <Grid item style={{ padding: 5 }}>
              <Typography
                className={classes.checkoutResume}
                variant="caption"
                display="block"
                align="left"
              >
                : {props.discount} %
              </Typography>
              <Typography
                 style={{ color: "#8EBF24" }}
                 className={classes.checkoutResume}
                 align="left"
                 variant="caption"
                 display="block"
              >
                 : {totalAfterDiscount.toFixed(3)} {currencySymbol}
              </Typography>
            </Grid>
            {appliedLoyaltyCard ? (
              <Grid item style={{ padding: 5 }}>
                <Chip
                  style={{
                    backgroundColor: "#548C1C",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  label={
                    <>
                      Loyalty Points:{" "}
                      {appliedLoyaltyCard?.redeem_points_amount
                        ? appliedLoyaltyCard?.redeem_points_amount?.toFixed(3)
                        : (0.0).toFixed(3)}{" "}
                      {currencySymbol}
                    </>
                  }
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Modal hideBackdrop={true} open={keyboardVisibility}>
        <ClickAwayListener onClickAway={() => setKeyboardVisibility(false)}>
          <Box className={classes.virtualKeyboard}>
            <Keyboard
              keyboardRef={(r) => (keyboard.current = r)}
              layoutName={layout}
              layout={french.layout}
              display={{
                "{capslock}": capsLockEnabled ? "🔒" : "🔓",
              }}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </Box>
        </ClickAwayListener>
      </Modal>

      <AlertModal
        setOpenModal={open ? setOpen : setOpenAlert}
        handleCancel={handleCancel}
        openModal={open || openAlert}
        title={
          open ? t("checkout:defaultClientWarning") : t("payement:alertPayment")
        }
        defaultClient={open && defaultClient}
        isPricingRule
      />
    </div>
  );
};
