import React, {useEffect, useRef, useState} from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    Modal,
    Typography,
    TextField,
    Checkbox,
    Grid,
    InputLabel,
    IconButton,
    ClickAwayListener,
} from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import MoneyIcon from "@material-ui/icons/Money";
import * as localforage from "localforage";
import {GlobalfilterData} from "../../helpers/helper";
import {useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import {
    LoadClosingEntry,
    LoadPOSInvoices,
    LoadZInvoices,
    posClosingEntry,
} from "../../store/pos/pos.action";
import {logout, logoutUser} from "../../store/login/login.action";
import {SetCushFund, SetSignature} from "../../store/login/login.action";
import {updateSession} from "../../store/pos/pos.action";
import {ZReport} from "./components/zReport";
import ReactToPrint from "react-to-print";
import {formatDate, timeout} from "../../helpers/utils";
import SignaturePad from "react-signature-pad-wrapper";
import {XReport} from "./components/xReport";
import {useReactToPrint} from "react-to-print";
import {UpdateThemes} from "../../store/theme/theme.action";
import {Loader} from "../../components/loader";
import {
    createCoffreOperation,
    loadCoffreOperation,
} from "../../store/pos/pos.action";
import moment from "moment";
import {SimpleTable} from "../../components/simpleTable";
import {useTranslation} from "react-i18next";
import {CreatebulkHold} from "../../store/hold/hold.action";
import {ButtonsFunction} from "../../helpers/buttonsFunction";
import DataRender from "../../components/tables/DataRender";
import { IndeterminateCheckBox as IndeterminateCheckBoxIcon, AddBox as AddBoxIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0.75rem 0px 0.8125rem",
        flexWrap: "wrap",
        "& .MuiInputBase-root": {
            background: theme.palette.white.main,
        },
        "& .MuiOutlinedInput-input": {
            padding: "8.9px 14px",
            fontSize: 12,
        },
    },
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 10,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    formControl: {
        maxWidth: 300,
        marginRight: 15,
        "& .MuiOutlinedInput-input": {
            padding: "8px 14px !important",
            fontSize: 12,
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: 10,
        },
    },
    button: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.blue.main,
        border: "1px solid",
        borderColor: theme.palette.secondary.main,
        fontSize: "0.875rem",
        borderRadius: 10,
    },
    icon: {
        marginRight: "0.375rem",
    },
    label: {
        fontSize: 12,
        marginBottom: 6,
        color: theme.palette.gray.main,
        paddingRight: 220,
        justifyContent: "left",
    },
    date: {
        marginBottom: 15,
        marginRight: 15,
    },
    filterBlock: {
        "& .MuiOutlinedInput-input": {
            padding: "5.9px 14px !important",
            fontSize: 12,
        },
    },
    hidden: {
        display: "none",
    },
    signaturePad: {
        border: "1px solid gray",
    },
    root: {
        "& > *": {
            margin: theme.spacing(0.5),
        },
    },
    signature: {
        width: "400px",
        height: "200px",
        backgroundColor: "white",
    },
}));

export const Session = () => {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation(["common", "session"]);
    const talonProps = ButtonsFunction();
    var [filtredSession, setFiltredSession] = useState([]);
    const [searchSession, setSearchSession] = useState("");
    const [open, setOpen] = useState(false);
    const [openz, setOpenz] = useState(false);
    const [openx, setOpenx] = useState(false);
    const [openClearHold, setOpenClearHold] = useState(false);
    const [checked, setChecked] = useState(false);
    const [openCoffreVerif, setOpenCoffreVerif] = useState(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [selectionModel, setSelectionModel] = useState([]);
    const [openAddCoffreVerif, setOpenAddCoffreVerif] = useState(false);
    const [addDepositAmount, setAddDepositAmount] = useState('');

    const companyacounts = useSelector(
        (state) => state.Configuration.companyacounts
    );
    const closedEntry = useSelector((state) => state.Pos.closedEntry);
    const invoices = useSelector((state) => state.Pos.invoices);
    const openSession = useSelector((state) => state.Pos.open);
    const closedPosSucces = useSelector((state) => state.Pos.closedPosSucces);
    const cushFund = parseFloat(useSelector((state) => state.Login.cushFund));
    const signature = useSelector((state) => state.Login.signature);
    const selectedData = useSelector((state) => state.Login.selectedData);
    const user = useSelector((state) => state.Login.user);
    const coffreOperations = useSelector((state) => state.Pos.coffreOperations);
    const componentRef = useRef();
    const signPad = useRef({});
    const componentRefX = useRef();
    const themes = useSelector((state) => state.Theme.themes);
    const posOpeningEntry = useSelector((state) => state.Pos.open);
    const globalDefaults = useSelector((state) => state.Login.globalDefaults);
    const temporaryHolds = useSelector((state) => state.Checkout.temporaryHolds);

    /*const columns = [
        {key: "name", title: "common:ref"},
        {key: "owner", title: "common:user"},
        {key: "modified", title: "common:date"},
        {key: "status", title: "common:statut"},
        {key: "pos_profile", title: "common:caisse"},
    ];*/

    const columns = [
        {
            field: 'name',
            headerName: t('common:ref'),
            flex: 1
        },
        {
            field: 'owner',
            headerName: t('common:user'),
            flex: 1
        },
        {
            field: 'modified',
            headerName: t('common:date'),
            flex: 1
        },
        {
            field: 'status',
            headerName: t('common:statut'),
            // type: 'number',
            flex: 1
        },
        {
            field: 'pos_profile',
            headerName: t('common:caisse'),
            flex: 1
        },
    ];

    const columnVisibilityModel = {
        name: true,
        owner: true,
        modified: true,
    };

    useEffect(() => {
        if (
            filtredSession.length == 0 &&
            closedEntry.length > 0 &&
            searchSession == ""
        ) {
            setFiltredSession(closedEntry);
            setLoading(false);
        }
    }, [closedEntry]);

    const dispatch = useDispatch();
    const handleSearchProduct = (event) => {
        setSearchSession(event.target.value);
        setFiltredSession(
            GlobalfilterData(closedEntry, event.target.value, [
                "name",
                "pos_profile",
                "owner",
                "modified",
            ])
        );
    };

    const handleRedirection = (name) => {
        return history.push(`/session/pos-close/${name}`);
    };

    const HandleAmount = (price) => {
        dispatch(SetCushFund(price));
    };
    const handleChangeAddAmount = (e) => {
        const value = e.target.value;
        if (value === '' || (/^\d*\.?\d*$/.test(value) && !isNaN(parseFloat(value)))) {
          setAddDepositAmount(value);
        }
      };
    
      const handleAddDeposit = () => {
        const amountToAdd = parseFloat(addDepositAmount);
        if (!isNaN(amountToAdd) && amountToAdd > 0) {
          const data = {
            payment_method: "Cash",
            creation_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            type: "Deposit",
            amount: -amountToAdd,
            transactions: null,
            cashier: selectedData.cashier,
            warehouse: selectedData.store,
            opening_entry: posOpeningEntry.name,
          };
    
          dispatch(createCoffreOperation(data, companyacounts?.default_currency));
          setOpenAddCoffreVerif(false);
          setAddDepositAmount('');
    
        }
      };
    
      const handleSubtractMoney = () => {
        setOpenCoffreVerif(true);
      };
    const handleSubmit = async () => {
        dispatch(updateSession({pos: selectedData.cashier, amount: cushFund}));
        setOpenx(true);
    };
    const handleCloseDay = (save) => {
        trim();
        setOpenClearHold(false);
        if (save) {
            dispatch(CreatebulkHold(temporaryHolds));
        }
        dispatch(updateSession({pos: selectedData.cashier, amount: cushFund}));
        dispatch(
            posClosingEntry(
                openSession,
                caisseBalance,
                cushFund,
                globalDefaults?.default_company
            )
        ); // loading invoices and creating a pos closing entry
        talonProps.CancelCheckoutData();
        setOpenz(true);
        setOpen(false);
    };

    const handleLogout = async () => {
        setOpenz(false);
        await dispatch(logout());
        await timeout(2000);
    };
    const defaultTheme = () => {
        const lastThemeIndex = themes.findIndex((obj) => obj.active === 1);
        const newThemeIndex = 0;
        let theme = themes.find((s) => s.theme_id == newThemeIndex);
        themes[lastThemeIndex].active = 0;
        theme.active = 1;
        themes[newThemeIndex] = theme;
        setTimeout(() => {
            dispatch(UpdateThemes(themes));
        }, 3000);
    };

    useEffect(() => {
        localforage.getItem("reduxPersist:Pos", async function (err, value) {
            var item = JSON.parse(value);
            var open = null;
            if (item) {
                open = item.open;
            }
            if (open) {
                await dispatch(LoadClosingEntry());
                user && (await dispatch(LoadPOSInvoices(open, user.email)));
                setFiltredSession(closedEntry);
            }
        });
    }, []);

    const clear = () => {
        dispatch(SetSignature(signPad.current.clear()));
    };
    const trim = () => {
        dispatch(SetSignature(signPad.current.toDataURL("image/png")));
    };

    const handlePrint = useReactToPrint({
        content: () => componentRefX.current,
        // onAfterPrint: () => dispatch(SetSignature(null))
    });
    const getCashPaymentsBalance = (invoices) => {
        var ttlBalance = 0;
        invoices.length != 0 &&
        invoices.map((inv, i) => {
            inv.payments.map((p, i) => {
                if (p.mode_of_payment == "Cash") {
                    ttlBalance += p.amount;
                }
            });
        });
        return ttlBalance;
    };

    const coffreBalance =
        coffreOperations.length != 0
            ? coffreOperations.reduce((a, v) => (a = a + parseFloat(v.amount)), 0)
            : 0;
    const caisseBalance = (
        getCashPaymentsBalance(invoices) +
        cushFund -
        coffreBalance
    ).toFixed(3);
    const clearHold = (
        <div className={classes.paper}>
            <Typography align={"center"} color={"primary"}>
                {t("session:clearHold")}
            </Typography>
            <Box
                mt={3}
                justifyContent="center"
                display="flex"
                className={classes.root}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleCloseDay(true)}
                >
                    {t("common:yes")}
                </Button>
                <Button color="primary" onClick={() => handleCloseDay(false)}>
                    {t("common:no")}
                </Button>
            </Box>
        </div>
    );
    const reportz = (
        <div className={classes.paper}>
            <Typography align={"center"} color={"primary"}>
                {t("session:ticketz")}
            </Typography>
            <Box
                mt={3}
                justifyContent="center"
                display="flex"
                className={classes.root}
            >
                <ReactToPrint
                    trigger={() => (
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={!closedPosSucces}
                        >
                            {t("session:ImpRapportZ")}
                        </Button>
                    )}
                    content={() => componentRef.current}
                />
                <Button
                    color="primary"
                    onClick={() => {
                        handleLogout();
                        defaultTheme();
                    }}
                >
                    {t("session:seDeconnecter")}
                </Button>
                <Button color="primary" onClick={() => setOpenz(false)}>
                    {t("common:annuler")}
                </Button>
            </Box>
        </div>
    );

    const reportx = (
        <div className={classes.paper}>
            <Typography align={"center"} color={"primary"}>
                {t("session:ticketx")}
            </Typography>
            <Box
                mt={3}
                justifyContent="center"
                display="flex"
                className={classes.root}
            >
                <Button
                    onClickCapture={() => trim()}
                    onClick={handlePrint}
                    variant="contained"
                    color="primary"
                >
                    {t("session:ImpRapportX")}
                </Button>
                <Button
                    color="primary"
                    onClick={async () => {
                        await dispatch(logoutUser());
                        await timeout(2000);
                        await defaultTheme();
                    }}
                >
                    {t("session:seDeconnecter")}
                </Button>
                <Button color="primary" onClick={() => setOpenx(false)}>
                    {t("common:annuler")}
                </Button>
            </Box>
        </div>
    );

    const [depositAmount, setDepositAmount] = useState(0);

    const handleChangeAmount = (e) => {
        if (e.target.value) {
            if (parseFloat(e.target.value) > caisseBalance) {
                return null;
            } else {
                setDepositAmount(e.target.value);
            }
        } else {
            setDepositAmount(e.target.value);
        }
    };

    const handleDeposit = () => {
        const data = {
            payment_method: "Cash",
            creation_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            type: caisseBalance == depositAmount ? "Total" : "Partiel",
            amount: depositAmount,
            transactions: null,
            cashier: selectedData.cashier,
            warehouse: selectedData.store,
            opening_entry: posOpeningEntry.name,
        };
        dispatch(createCoffreOperation(data, companyacounts?.default_currency));
        setOpenCoffreVerif(false);
        setDepositAmount(0);
    };

    const addCoffreAlert = (
        <ClickAwayListener onClickAway={() => setOpenAddCoffreVerif(false)}>
          <div className={classes.paper}>
            <Typography align={"center"} color={"primary"}>
            {t("session:BalanceCaisse")}: {caisseBalance}{" "}
            {globalDefaults?.default_currency}
            </Typography>
            <Box mt={4} justifyContent="center" display="flex">
              <Grid container justifyContent="center">
                <InputLabel required className={classes.label}>
                  {t("common:amnt")} ({globalDefaults?.default_currency})
                </InputLabel>
                <FormControl variant="outlined" className={classes.formControl}>
                  <OutlinedInput
                    autoComplete="off"
                    id="outlined-adornment-add-amount"
                    value={addDepositAmount}
                    name="addCashAmount"
                    type="number"
                    onChange={handleChangeAddAmount}
                    aria-describedby="outlined-add-weight-helper-text"
                    inputProps={{
                      'aria-label': 'add-weight',
                    }}
                    labelWidth={0}
                  />
                </FormControl>
              </Grid>
            </Box>
            <Box mt={4} justifyContent="center" display="flex">
              <Button
                disabled={!parseFloat(addDepositAmount) || parseFloat(addDepositAmount) <= 0}
                variant="contained"
                color="primary"
                onClick={handleAddDeposit}
              >
                {t("session:RemiseCoffre")}
              </Button>
              <Button color="primary" onClick={() => setOpenAddCoffreVerif(false)}>
                {t("common:annuler")}
              </Button>
            </Box>
          </div>
        </ClickAwayListener>
      );

    const coffreAlert = (
        <ClickAwayListener onClickAway={() => setOpenCoffreVerif(false)}>
            <div className={classes.paper}>
                <Typography align={"center"} color={"primary"}>
                    {t("session:BalanceCaisse")}: {caisseBalance}{" "}
                    {globalDefaults?.default_currency}
                </Typography>
                <Box Box mt={4} justifyContent="center" display="flex">
                    <Grid container justifyContent="center">
                        <InputLabel required className={classes.label}>
                            {t("common:amnt")} ({globalDefaults?.default_currency})
                        </InputLabel>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <OutlinedInput
                                autoComplete="off"
                                id="outlined-adornment-weight"
                                value={depositAmount}
                                name="cashAmount"
                                type="number"
                                onChange={(e) => handleChangeAmount(e)}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                    "aria-label": "weight",
                                }}
                                labelWidth={0}
                            />
                        </FormControl>
                    </Grid>
                </Box>
                <Box Box mt={4} justifyContent="center" display="flex">
                    <Button
                        disabled={!parseFloat(depositAmount) && depositAmount < 1}
                        variant="contained"
                        color="primary"
                        onClick={handleDeposit}
                    >
                        {t("session:RemiseCoffre")}
                    </Button>
                    <Button color="primary" onClick={() => setOpenCoffreVerif(false)}>
                        {t("common:annuler")}
                    </Button>
                </Box>
            </div>
        </ClickAwayListener>
    );
    const closeDay = async () => {
        if (temporaryHolds?.length > 0) {
            setOpenClearHold(true);
        } else {
            handleCloseDay(false);
        }
    };
    const alert = (
        <div className={classes.paper}>
            <Typography align={"center"} color={"primary"}>
                {t("session:AjtMontCaisse")}
            </Typography>
            <Box mt={3} justifyContent="center" display="flex">
            <TextField
                    id="outlined-basic"
                    label={t("common:amnt")}
                    variant="outlined"
                    // onChange={(e)=>{if (!isNaN (e.target.value) || (e.target.value === ".")) HandleAmount(e.target.value)}}
                    value={caisseBalance}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MoneyIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                              {globalDefaults?.default_currency}
                              <IconButton
                                color="primary"
                                onClick={() => setOpenAddCoffreVerif(true)}
                              >
                                <AddBoxIcon />
                              </IconButton>
                              <IconButton
                                color="primary"
                                onClick={handleSubtractMoney}
                              >
                                <IndeterminateCheckBoxIcon />
                              </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box
                mt={3}
                justifyContent="center"
                display="flex"
                className={classes.signaturePad}
            >
                <SignaturePad
                    ref={signPad}
                    redrawOnResize
                    options={{penColor: "#2F4B7C"}}
                />
            </Box>
            <Box mt={1} justifyContent="end" display="flex">
                <Button color="primary" variant="contained" onClick={clear}>
                    {t("session:nettoyer")}
                </Button>
            </Box>

            {/* ***** */}

            <Box mt={3} justifyContent="center">
                <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    color="primary"
                />
                {t("session:FermetureJournee")}
            </Box>
            {checked && (
                <Box
                    Box
                    mt={4}
                    justifyContent="center"
                    display="flex"
                    className={classes.root}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => closeDay()}
                    >
                        {t("session:FermerJournée")}
                    </Button>
                    <Button color="primary" onClick={() => setOpen(false)}>
                        {t("common:annuler")}
                    </Button>
                </Box>
            )}
            {!checked && (
                <Box
                    mt={4}
                    justifyContent="center"
                    display="flex"
                    className={classes.root}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        /*disabled={cushFund == 0 || cushFund == ""}*/ onClick={() =>
                        handleSubmit()
                    }
                    >
                        {t("session:FermerCaisse")}
                    </Button>
                    <Button color="primary" onClick={() => setOpen(false)}>
                        {t("common:annuler")}
                    </Button>
                </Box>
            )}
        </div>
    );

    useEffect(() => {
        posOpeningEntry &&
        dispatch(loadCoffreOperation({opening_entry: posOpeningEntry.name}));
    }, [openCoffreVerif]);

    console.log('filtredSession : ', filtredSession);

    return (
        <Box>
            <Box className={classes.header}>
                <Button
                    onClick={() => setOpen(true)}
                    className={classes.button}
                    size={"small"}
                    variant="outlined"
                    color="primary"
                >
                    {t("session:FermerCaisse")}
                </Button>
            </Box>
            {loading ? (
                <Loader/>
            ) : (
                <Box>
                    <DataRender
                        data={filtredSession}
                        loading={false}
                        selectionModel={selectionModel}
                        setSelectionModel={setSelectionModel}
                        handleEdit={handleRedirection}
                        tag={'name'}
                        noActions={false}
                        isViewAction={true}
                        columnsDef={columns}
                        columnVisibilityModel={columnVisibilityModel}
                    />
                </Box>
            )}
            <Modal
                open={open}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {alert}
            </Modal>
            <Modal
                open={openClearHold}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {clearHold}
            </Modal>
            <Modal
                open={openAddCoffreVerif}
                aria-labelledby="add-coffre-modal-title"
                aria-describedby="add-coffre-modal-description"
            >
                {addCoffreAlert}
            </Modal>
            <Modal
                open={openz}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {reportz}
            </Modal>
            <Modal
                open={openx}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {reportx}
            </Modal>
            <Modal
                open={openCoffreVerif}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {coffreAlert}
            </Modal>
            <div className={classes.hidden}>
                <ZReport
                    details={closedPosSucces}
                    signature={signature}
                    ref={componentRef}
                    caisseBalance={caisseBalance}
                />
            </div>
            <div className={classes.hidden}>
                <XReport
                    isSign={true}
                    isOpen={false}
                    details={invoices}
                    signature={signature}
                    ref={componentRefX}
                />
            </div>
        </Box>
    );
};
