
import Box from "@material-ui/core/Box";
import AddItem from "../../../../components/addItem/AddItem";
import { SetNotification } from "../../../../store/notification/notification.action";
import { Delete } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router';
import { Loader } from "../../../../components/loader";
import axios from 'axios';
import { useTranslation } from "react-i18next";

import useGetAxios from "../../../initialization/useGetAxios";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Autocomplete from "@mui/material/Autocomplete";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  TextField,
  Collapse,
  LinearProgress,
  FormControl,
  InputAdornment,
  Link,
  Select,
  Modal,
  OutlinedInput,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { Button } from "@material-ui/core";
import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import useGetDoc from "../../../../hooks/useGetDoc";
import React, {useEffect, useState} from "react";
import {formatDate} from "../../../../helpers/utils";
import {fr} from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import {SpaceBar} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
const DuplicateQuotation = ({setCloseModal,id}) => {
   // const { id } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation(["buying", "common"]);
    const[supplierList, setSupplierList] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const[itemList, setItemList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [CurrencyList, setCurrencyList] = useState([]);
    const [taxList, setTaxList] = useState([]);
    const [supplierSelected, setSupplierSelected] = useState('');
    const [companySelected, setCompanySelected] = useState('');
    const [quotationNo, setQuotationNo] = useState('');
    const [addressList, setAddressList] = useState([]);
    const [addressSelected, setAddressSelected] = useState('');
    const [contactList, setContactList] = useState([]);
    const [contactSelected, setContactSelected] = useState('');
    const [CurrencySelected, setCurrencySelected] = useState('');
    const [taxCategoryList, setTaxCategoryList] = useState([]);
    const [priceList, setPriceList] = useState([]);
    const [priceSelected, setPriceSelected] = useState('');
    const [taxCategorySelected, setTaxCategorySelected] = useState('');
    const [ purchaseTaxTemplateList, setPurchaseTaxTemplateList] = useState([]);
    const [purchaseTaxTemplateSelected, setPurchaseTaxTemplateSelected] = useState('');
    const [title,setTitle] = useState('')
   // const [dataQuotationList, setDataQuotationList] = useState([]);

    const [error, setError] = useState({
        supplier: false,
        scheduleDate: false,
        scheduleDateTill: false,
        items: false,
        taxes: false,
    });
    const [scheduleDate, setScheduleDate] = useState(
        formatDate(new Date(), "YYYY-MM-DD")
    );
    const [scheduleDateTill, setScheduleDateTill] = useState(
        formatDate(new Date(), "YYYY-MM-DD")
    );
    const [state, setState] = useState({
        items: [],
        taxes: []
        
    });
    
    
    const handleQuantityChange = (index, value) => {
        const updatedItems = [...state.items];
        updatedItems[index].qty = value;
        setState({ ...state, items: updatedItems });
      };
    
      const handleDeleteItem = (index) => {
        const updatedItems = [...state.items];
        updatedItems.splice(index, 1);
        setState({ ...state, items: updatedItems });
      
      };
   
    //   const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Supplier%20Quotation&name=${id}`);

 
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Set loading to false whether data fetching succeeds or fails

          try {
            const response = await axios.get(`${process.env.REACT_APP_API_URI}/api/details`, {
              params: {
                doctype: 'Supplier Quotation',
                name: id.name
              },
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                Authorization: JSON.parse(localStorage.getItem("user")).token,
                
              }
            });
             
            // Operations to be executed after data fetching is completed successfully
            response.data['docs'].forEach((item) => { 
                setQuotationNo(item.quotation_number);
                setTitle(item.supplier);
                setSupplierSelected(item.supplier);
                setCompanySelected(item.company);
                setCurrencySelected(item.currency);
                setPriceSelected(item.price_list);
                setPurchaseTaxTemplateSelected(item.purchase_tax_template);
                setTaxCategorySelected(item.tax_category);
                setScheduleDate(item.transaction_date                    );
                setScheduleDateTill(item.valid_till                    );
                setPurchaseTaxTemplateSelected(item.taxes_and_charges);  
                setPriceSelected(item.buying_price_list);                  
                setAddressSelected(item.supplier_address);
                setContactSelected(item.contact_person);
                item.taxes.map((item)=>{
                    console.log('item tax',item)
                   state.taxes.push({
                       item_code: item.item_code,
                       name: item.account_head,
                       account_head: item.account_head,
                       description: item.description,
                       rate: item.tax_rate,
                       amount: item.amount

                })
                })
                //setTaxCategorySelected(item.tax_category);
                item.items.map((item)=>{
                    state.items.push(item)
                    
                })
            });
            setLoading(false); // Set loading to false whether data fetching succeeds or fails
             
            
          } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error as needed
          } finally {
            setLoading(false); // Set loading to false whether data fetching succeeds or fails
          }
        };
    
        fetchData();
      }, []);
    // useEffect(() => {
    //     const fetchData = async () => {
           
    //       try {
    //         const response = await axios.get('http://localhost:8000/api/details', {
    //           params: {
    //             doctype: 'Supplier Quotation',
    //             name: 'PUR-SQTN-2024-00111'
    //           },
    //           headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //             "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    //             Authorization: JSON.parse(localStorage.getItem("user")).token,
    //         },
    //         });
    //         setDataQuotationList(response.data)
          
    //         // setData(response.data);
    //         // setLoading(false);
    //       } catch (error) {
    //         setError(error);
    //         // setLoading(false);
    //       }
    //     };
    
    //     fetchData();
    //   }, []);

    const getSupplierList = () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
            body: JSON.stringify({
                doctype: "Supplier",
                fields: ["`tabSupplier`.`name`"]

            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setSupplierList(data);
                }
            });
    };
    const getItemList = () => {
        if(companySelected){
            fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
                method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                        Authorization: JSON.parse(localStorage.getItem("user")).token,
                    },
                body: JSON.stringify({
                    doctype: "Item",
                    fields: [ "`tabItem`.`name`",
                    "`tabItem`.`description`",
                    "`tabItem`.`item_group`",
                    "`tabItem`.`item_collection`",
                    "`tabItem`.`item_name`",
                    "`tabItem`.`standard_rate`",
                    "`tabItem`.`opening_stock`",

                    "`tabItem`.`image`",
                    "`tabItem`.`net_amount`",
                    "`tabItem`.`net_rate`",
                    "`tabItem`.`price_list_rate`",
                    "`tabItem`.`discount_amount`",
                    "`tabItem Tax`.`item_tax_template`",
                    "`tabItem`.`is_stock_item`",
                    "`tabItem`.`has_variants`",
                    "`tabItem`.`default_material_request_type`",
                    "`tabItem`.`net_rate`",
                    "`tabItem`.`standard_rate`",
                    "`tabItem`.`item_code`",
                    "`tabItem`.`description`",
                    "`tabItem`.`item_group`",
                    "`tabItem`.`total_projected_qty`",
                    
                ],
                    filters: [["Item Default","company","=",companySelected]],
    
                }),
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (Array.isArray(data)) {
                        
                        setItemList(data);
                    }
                });
        }
    
    };
    const getCompanyList = () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
            body: JSON.stringify({
                doctype: "Company",
                fields: [ "`tabCompany`.`name`"]

            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setCompanyList(data);
                }
            });
    };
    const getTaxList = () => {
        if (companySelected) {
            fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
                body: JSON.stringify({
                    doctype: "Account",
                    fields: ["`tabAccount`.`name`", "`tabAccount`.`owner`", "`tabAccount`.`creation`", "`tabAccount`.`modified`", "`tabAccount`.`modified_by`", "`tabAccount`.`_user_tags`", "`tabAccount`.`_comments`", "`tabAccount`.`_assign`", "`tabAccount`.`_liked_by`", "`tabAccount`.`docstatus`", "`tabAccount`.`parent`", "`tabAccount`.`parenttype`", "`tabAccount`.`parentfield`", "`tabAccount`.`idx`", "`tabAccount`.`account_name`", "`tabAccount`.`account_number`", "`tabAccount`.`disabled`", "`tabAccount`.`account_type`","`tabAccount`.`tax_rate`"],
                    filters: [["Account", "account_type", "=", "Tax"], ["Account", "company", "=", companySelected]],
                }),
            })
            .then((res) => res.json())
            .then((data) => {
                if (Array.isArray(data)) {
                    setTaxList(data);

                    console.log("data",data)
                }
            });
        }
    };
 
    const getAddressList = () => {
        if(supplierSelected){
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
            body: JSON.stringify({
                doctype: "Address",
                fields: ["`tabAddress`.`name`","`tabAddress`.`owner`","`tabAddress`.`creation`","`tabAddress`.`modified`","`tabAddress`.`modified_by`","`tabAddress`.`_user_tags`","`tabAddress`.`_comments`","`tabAddress`.`_assign`","`tabAddress`.`_liked_by`","`tabAddress`.`docstatus`","`tabAddress`.`parent`","`tabAddress`.`parenttype`","`tabAddress`.`parentfield`","`tabAddress`.`idx`","`tabAddress`.`address_type`","`tabAddress`.`city`","`tabAddress`.`disabled`"],
                filters: [["Address","address_type","=","Billing"],["Dynamic Link","link_name","=",supplierSelected]]

            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setAddressList(data);
                }
            });
        }
    };
    const getCurrencyList = () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
            body: JSON.stringify({
                 doctype: "Currency",
fields: ["`tabCurrency`.`name`","`tabCurrency`.`owner`","`tabCurrency`.`creation`","`tabCurrency`.`modified`","`tabCurrency`.`modified_by`","`tabCurrency`.`_user_tags`","`tabCurrency`.`_comments`","`tabCurrency`.`_assign`","`tabCurrency`.`_liked_by`","`tabCurrency`.`docstatus`","`tabCurrency`.`parent`","`tabCurrency`.`parenttype`","`tabCurrency`.`parentfield`","`tabCurrency`.`idx`","`tabCurrency`.`enabled`","`tabCurrency`.`fraction`","`tabCurrency`.`fraction_units`","`tabCurrency`.`symbol`","`tabCurrency`.`number_format`"],
         filters: [["Currency","enabled","=",1]]

            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setCurrencyList(data);
                }
            });
    };
    const getConstactList = () => {
        if(supplierSelected){
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
            body: JSON.stringify({
                doctype: "Contact",
                fields: ["`tabContact`.`name`","`tabContact`.`owner`","`tabContact`.`creation`","`tabContact`.`modified`","`tabContact`.`modified_by`","`tabContact`.`_user_tags`","`tabContact`.`_comments`","`tabContact`.`_assign`","`tabContact`.`_liked_by`","`tabContact`.`docstatus`","`tabContact`.`parent`","`tabContact`.`parenttype`","`tabContact`.`parentfield`","`tabContact`.`idx`","`tabContact`.`email_id`","`tabContact`.`status`","`tabContact`.`phone`","`tabContact`.`image`"],
                filters: [["Dynamic Link","link_name","=",supplierSelected],["Contact","status","=","Passive"]]

            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setContactList(data);
                }
            });
        }
    };
    const getTaxCategoryList = () => {
       
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
            body: JSON.stringify({
                doctype: "Tax Category",
                fields: ["`tabTax Category`.`name`","`tabTax Category`.`owner`","`tabTax Category`.`creation`","`tabTax Category`.`modified`","`tabTax Category`.`modified_by`","`tabTax Category`.`_user_tags`","`tabTax Category`.`_comments`","`tabTax Category`.`_assign`","`tabTax Category`.`_liked_by`","`tabTax Category`.`docstatus`","`tabTax Category`.`parent`","`tabTax Category`.`parenttype`","`tabTax Category`.`parentfield`","`tabTax Category`.`idx`","`tabTax Category`.`title`","`tabTax Category`.`disabled`"],
                filters: [["Tax Category","disabled","=",0]]

            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setTaxCategoryList(data);
                }
            });
        
    };
    const getPriceList = () => {
       
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
            body: JSON.stringify({
                doctype: 'Price List',
        fields: [
            '`tabPrice List`.`name`',
            '`tabPrice List`.`price_list_name`',
            '`tabPrice List`.`buying`',
            '`tabPrice List`.`selling`',
            '`tabPrice List`.`currency`'
        ],
        filters: [["Price List","buying","=",1]]
            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setPriceList(data);
                }
            });
        
    };
    const getPurchaseTaxTemplateList = () => {
        if(companySelected){
            fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
                method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                        Authorization: JSON.parse(localStorage.getItem("user")).token,
                    },
                body: JSON.stringify({
                    doctype: "Purchase Taxes and Charges Template",
    fields: ["`tabPurchase Taxes and Charges Template`.`name`","`tabPurchase Taxes and Charges Template`.`owner`","`tabPurchase Taxes and Charges Template`.`creation`","`tabPurchase Taxes and Charges Template`.`modified`","`tabPurchase Taxes and Charges Template`.`modified_by`","`tabPurchase Taxes and Charges Template`.`_user_tags`","`tabPurchase Taxes and Charges Template`.`_comments`","`tabPurchase Taxes and Charges Template`.`_assign`","`tabPurchase Taxes and Charges Template`.`_liked_by`","`tabPurchase Taxes and Charges Template`.`docstatus`","`tabPurchase Taxes and Charges Template`.`parent`","`tabPurchase Taxes and Charges Template`.`parenttype`","`tabPurchase Taxes and Charges Template`.`parentfield`","`tabPurchase Taxes and Charges Template`.`idx`","`tabPurchase Taxes and Charges Template`.`is_default`","`tabPurchase Taxes and Charges Template`.`disabled`","`tabPurchase Taxes and Charges Template`.`company`"],
    filters: [["Purchase Taxes and Charges Template","company","=",companySelected]]
                }),
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (Array.isArray(data)) {
                        setPurchaseTaxTemplateList(data);
                    }
                });
        }
       
      
        
    };
    const handleSaveQuotation = () => {
       setLoadingButton(true);
        const data2 = {
          doc: {
            "docstatus": 0,
        "doctype": "Supplier Quotation",
        "title": title,
        "naming_series": "PUR-SQTN-.YYYY.-",
        "company": companySelected,
        "transaction_date": scheduleDate,
        "currency": "TND",
        "buying_price_list": priceSelected,
        //"price_list_currency": "TND",
        "apply_discount_on": "Grand Total",
        "disable_rounded_total": 1,
        "letter_head": "xxxxxxxx",
        "status": "Draft",
        "is_subcontracted": "No",
        "items": state.items.map((item) => {

          return {
            
                "docstatus": 0,
                "doctype": "Supplier Quotation Item",
                "rate": item.standard_rate,
                "amount": item.standard_rate,
                "base_rate": item.base_rate,
                "base_amount": item.base_amount,
                "net_rate": item.net_rate,
                "net_amount": item.net_amount,
                "base_net_rate": item.base_net_rate,
                "base_net_amount": item.base_net_amount,
                "discount_amount": item.discount_amount,
                "is_free_item": 0,
                "item_group": "All Item Groups",
                "stock_uom": "Nos",
                "page_break": 0,
                "parent": "new-supplier-quotation-2",
                "parentfield": "items",
                "parenttype": "Supplier Quotation",
                "idx": 1,
                "qty": item.qty,
                "stock_qty": 1,
                "item_code": item.item_code,
                "item_name": item.item_name,
                "description": "test",
                "transaction_date": scheduleDate,
                "has_margin": false,
                "child_docname": "new-supplier-quotation-item-2"
            
          }
        }),
        "supplier": supplierSelected,
        "valid_till": scheduleDateTill,
        "conversion_rate": 1,
        "supplier_name": supplierSelected,
        "supplier_address": addressSelected,
        "taxes_and_charges": purchaseTaxTemplateSelected,
        "taxes": 
         state.taxes.map((tax)=>{ 
             return {
                    "docstatus": 0,
                    "doctype": "Purchase Taxes and Charges",
                    "account_currency": null,
                    "parent": "new-supplier-quotation-2",
                    "parentfield": "taxes",
                    "parenttype": "Supplier Quotation",
                    "idx": 1,
                    "account_head": tax.account_head,
                    "description":tax.description,
                    "rate": tax.tax_rate,
             }
         }),
        "contact_person": contactSelected,
        "tax_category":taxCategorySelected,
        "quotation_number": quotationNo,
        
    },
"action": "Save"
        }
        const data = {
            "doc": {
              "docstatus": 0,
              "doctype": "Supplier Quotation",
              "name": "new-supplier-quotation-1",
              "__islocal": 1,
              "__unsaved": 1, 
              "naming_series": "PUR-SQTN-.YYYY.-",
              "company":  companySelected,
              "transaction_date": scheduleDate,
               "buying_price_list":  priceSelected,
               "ignore_pricing_rule": 0,
               
                "letter_head": "xxxxxxxx",
              "status": "Draft",
               "supplier": supplierSelected, 
              "valid_till":  scheduleDateTill, 
               "items": state.items.map((item) => {  
               return  {
                  "docstatus": 0,
                  "doctype": "Supplier Quotation Item",
                  "name": "new-supplier-quotation-item-1",
                  "__islocal": 1,
                  "__unsaved": 1,
                   "item_group": item.item_group,
                  "stock_uom": item.stock_uom,
                   "parent": "new-supplier-quotation-1",
                  "parentfield": "items",
                  "parenttype": "Supplier Quotation",
                  "idx": 1,
                  "item_code":item.item_code,
                  "item_name": item.item_name,
                  "lead_time_days": 0,
                  "description": item.description,
                  "tax_rate": item.tax_rate,
                  "tax_amount": 0,
                  "total_amount": 0,
                  "brand": item.brand, 
                  "qty": item.qty,
                  "uom": item.uom, 
                  "rate": item.rate,
                  "amount": item.amount,
                  "item_tax_template":item.item_tax_template,
                  "base_rate": item.base_rate,
                  "base_amount": item.base_amount,
                  "net_rate": item.net_rate,  
                  "stock_qty": item.stock_qty,
                  "has_margin": false,
                  "child_docname": "new-supplier-quotation-item-1"
                }
            }),
              "taxes":state.taxes.map((tax)=>{
                 console.log('tax',tax);
                return {
                  "docstatus": 0,
                  "doctype": "Purchase Taxes and Charges",
                  "account_currency": null,
                  "parent": "new-supplier-quotation-2",
                  "parentfield": "taxes",
                  "parenttype": "Supplier Quotation",
                  "idx": 1,
                  "account_head": tax.account_head ? tax.account_head : tax.name,
                  "description":tax.description ? tax.description : "",
                  "rate": tax.rate,
                }
              }), 
             
            },
            "action": "Save"
          }
        console.log('all data',data);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user"))?.token,
          },
          body: JSON.stringify(data),
        })
          .then((res) => {
            setLoadingButton(false);
            if (!res.ok) {
              dispatch(
                SetNotification({ code: "error", message: "Something went wrong" })
              );
            }
            return res.json();
          })
          .then((data) => {
            if (data?.success === false) {
                setLoadingButton(false);
              dispatch(
                SetNotification({ code: "warning", message: data?.message })
              );
            } else {
                setLoadingButton(false);
              dispatch(
                SetNotification({
                  code: "success",
                  message: `Supplier Quotation Duplicated successfully`,
                })
              );
              handleReload();
              handleCancel();
            }
          });
      };
   const handleReload = () => {
     window.location.reload();
   }
     
    useEffect(() => {
        getSupplierList();
            getCompanyList(); 
            getCurrencyList();
            getTaxCategoryList();
            getPriceList();       
        
    },[]);
    useEffect(() => {
        getTaxList();
        getPurchaseTaxTemplateList()
        getItemList();
    }, [companySelected]);
    useEffect(()=>{
        getAddressList();
        getConstactList();
    },[supplierSelected])
    const handleChangeSelectmultiple = (event) => {
        setError({ ...error, [event.target.name]: false });
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const handleDateChange = (date, event) => {
        isNaN(Date.parse(date))
            ? setError({ ...error, scheduleDate: false })
            : setScheduleDate(date.toISOString().slice(0, 10));
    };
    const handleDateTillChange = (date, event) => {
        isNaN(Date.parse(date))
            ? setError({ ...error, scheduleDateTill: false })
            : setScheduleDateTill(date.toISOString().slice(0, 10));
    };
    const handleCancel = () => {
        setCloseModal(); // Call setCloseModal to close the modal
      };
      if (loading) {
        return <Loader />; // Render loader while data is being fetched
      }
     return (
        <Card
        sx={{
          boxShadow: 20,
          marginLeft: "-20px",
          marginRight: "-20px",
          marginTop: "-5px",
          marginBottom: "-12px",
        }}b 
      >
        <div style={{ margin: "15px" }}>
          <Grid item xs={12} style={{ marginTop: 20, fontWeight: "bold" }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              style={{ fontSize: "1.25rem", fontWeight: 800 }}
            >
              <AddBoxIcon sx={{ mr: 1 }} style={{ color: "#0b68ff" }} />
              <div style={{ color: "#2F4B7C" }}>
                  {t("common:SupplierQuotation")}:{ id.name} 
              </div>
            </Box>
          </Grid>
        </div>
        <Divider orientation="horizontal" fullWidth />
  
        <div style={{ marginLeft: "40px" }}>
          <Grid container spacing={5} style={{ paddingLeft: "3%" }}>
            <Grid item xs={6}>
              <FormControl style={{ marginTop: 35 }}>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  {t("common:supplier")}
                </InputLabel>
                <Select
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  labelId="select-label"
                  id="simple-select"
                  value={supplierSelected}
                  label={t("common:supplier")}
                  onChange={(e) => {
                    setSupplierSelected(e.target.value);
                  }}
                >
                  {supplierList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
  
              <Grid style={{ marginTop: 25 }}>
                <MuiPickersUtilsProvider
                  padding={50}
                  locale={fr}
                  utils={DateFnsUtils}
                >
                  <InputLabel className={"label"}>{t("common:date")}</InputLabel>
                  <KeyboardDatePicker
                    className={"date"}
                    style={{ marginTop: 10, width: "80%" }}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    name="scheduleDate"
                    inputVariant="outlined"
                    id="date-picker-inline"
                    value={scheduleDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    // minDate={formatDate(new Date(), "YYYY-MM-DD")}
                    // minDateMessage={"common:minDateMessage"}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
  
            <Grid item xs={6}>
              <FormControl fullWidth style={{ marginTop: 35 }}>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  {t("common:company")}
                </InputLabel>
                <Select
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  labelId="select-label"
                  id="simple-select"
                  value={companySelected}
                  label={t("common:company")}
                  onChange={(e) => setCompanySelected(e.target.value)}
                >
                  {companyList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
  
              <Grid style={{ marginTop: 25 }}>
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                  <InputLabel className={"label"} style={{ fontSize: "16px" }}>
                  {t("common:ValidTill")}
                  </InputLabel>
                  <KeyboardDatePicker
                    className={"date"}
                    style={{ marginTop: 10, width: "80%" }}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    name="scheduleDateTill"
                    inputVariant="outlined"
                    id="date-till-picker-inline"
                    value={scheduleDateTill}
                    onChange={handleDateTillChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    minDate={formatDate(new Date(), "YYYY-MM-DD")}
                    minDateMessage={"common:minDateMessage"}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
  
            <Grid item xs={6}>
            <Autocomplete
              multiple
              id="items-autocomplete"
              sx={{  width: "100%" , marginTop: "29px" }}
              options={itemList}
              getOptionLabel={(option) =>
                option.item_name + " - " + option.item_code
              }
              value={state.items}
              onChange={(event, newValue) => {
                handleChangeSelectmultiple({
                  target: {
                    name: "items",
                    value: newValue.map((item) => ({
                      ...item,
                      quantity: 1,
                      last_purchase_rate: item.last_purchase_rate,
                      base_amount: item.last_purchase_rate,
                    })),
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("common:Items")}
                  error={!!error.items}
                  helperText={error.items}
                  fullWidth
                  sx={{ width: "80%" }}
                />
              )}
            />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="qnumber"
                className="CuFormInput"
                style={{ marginTop: "28px", width: "80%" }}
                onChange={(e) => setQuotationNo(e.target.value)}
                label={t("common:QuotationNumber")}
                variant="outlined"
                value={quotationNo}
              />
            </Grid>
  
            {state.items.length > 0 ? (
              <Grid item xs={11}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          {t("common:Items")}
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          {t("common:qty")}
                        </TableCell>
                        <TableCell> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.items.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.item_name}</TableCell>
                          <TableCell>
                            <TextField
                              required
                              type="number"
                              value={item.qty}
                              onChange={(e) =>
                                handleQuantityChange(index, e.target.value)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleDeleteItem(index)}>
                              <Delete color="error" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            ) : null}
  
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {t("common:SupplierAddress")}
                </InputLabel>
                <Select
                  labelId="select-label"
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  id="simple-select"
                  value={addressSelected}
                  label={t("common:SupplierAddress")}
                  onChange={(e) => {
                    setAddressSelected(e.target.value);
                  }}
                >
                  {addressList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  id=" simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {t("common:ContactPerson")}
                </InputLabel>
                <Select
                  labelId="select-label-item"
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  id="simple-select-item"
                  value={contactSelected}
                  label={t("common:ContactPerson")}
                  onChange={(e) => setContactSelected(e.target.value)}
                >
                  {contactList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  id=" simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {t("common:currency")}
                </InputLabel>
                <Select
                  labelId="select-label-item"
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  id="simple-select-item"
                  value={CurrencySelected}
                  label={t("common:currency")}
                  onChange={(e) => setCurrencySelected(e.target.value)}
                >
                  {CurrencyList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  id=" simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {t("common:PriceList")}
                </InputLabel>
                <Select
                  labelId="select-label-item"
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  id="simple-select-item"
                  value={priceSelected}
                  label={t("common:PriceList")}
                  onChange={(e) => setPriceSelected(e.target.value)}
                >
                  {priceList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
  
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  id=" simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {t("common:TaxCategory")}
                </InputLabel>
                <Select
                  labelId="select-label-item"
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  id="simple-select-item"
                  value={taxCategorySelected}
                  label={t("common:TaxCategory")}
                  onChange={(e) => setTaxCategorySelected(e.target.value)}
                >
                  {taxCategoryList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  id=" simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {t("common:PurchaseTax")}
                </InputLabel>
                <Select
                  labelId="select-label-item"
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  id="simple-select-item"
                  value={purchaseTaxTemplateSelected}
                  label={t("common:PurchaseTax")}
                  onChange={(e) => setPurchaseTaxTemplateSelected(e.target.value)}
                >
                  {purchaseTaxTemplateList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
  
            <Grid item xs={6}>
              <AddItem
                label={t("common:Taxes")}
                name="taxes"
                value={state.taxes}
                error={error.taxes}
                propToRender={"name"}
                handleChange={handleChangeSelectmultiple}
                defaultItem={""}
                data={taxList}
              />
            </Grid>
            
  
            <Grid item xs={12} style={{ margin: "20px" , marginRight :"15%" }}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  size="large"
                  disabled={loadingButton}
                  color="primary"
                  variant="contained"
                  onClick={handleSaveQuotation}
                  style={{ marginRight: "16px" }} // Add margin to separate buttons
                >
                  {t("common:save")}
                </Button>
                <Button
                  size="large"
                  color="error"
                  variant="contained"
                  onClick={handleCancel}
                >
                  {t("common:annuler")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Card>

    )
   
     
}

export default DuplicateQuotation;