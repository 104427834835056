import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useStyles } from "../../style";
import { useTableStyles } from "../../../../styles/table.style";
import { GlobalfilterData } from "../../../../helpers/helper";
import {
  Box,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  OutlinedInput,
  ClickAwayListener,
  Select,
  MenuItem,
  Typography,
  Modal,
  Chip,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";
import PaymentIcon from "@material-ui/icons/Payment";
import { Deposit } from "../deposit";
import { Calculator } from "../../../../components/calculator";
import Hold from "../../../../components/hold";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, symbol } from "../../../../helpers/utils";
import {
  CreateOrder,
  SetCustomRef,
  SetCouponCode,
  UpdatePayment,
  SetGiftCardCouponCode,
  fetchAvoirList,
  SetTPEticketNumber,
} from "../../../../store/checkout/checkout.action";
import {
  LoadPricingRuleByName,
  updateClientPricingRule,
  cancelPaymentEntry,
} from "../../../../store/client/client.action";
import { useTranslation } from "react-i18next";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import { SetNotification } from "../../../../store/notification/notification.action";
import AlertModal from "../../../../components/common/alertModal";
import { FormTpe } from "./formTpe";
import { FormCheque } from "./formCheque";
import moment from "moment";
import { UpdateAppliedLoyCard } from "../../../../store/cart/cart.action";
import { FormCoffre } from "./formCoffre";
import { ButtonsFunction } from "../../../../helpers/buttonsFunction";
//import { calculateTotalWithTax, calculateTotalTaxAmount } from '../../../../pages/checkout/components/totalPrice/index';
export const PayementMethod = (props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const talonProps = ButtonsFunction();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["payement", "common", "checkout"]);

  const appliedLoyaltyCard = useSelector(
    (state) => state.Cart.appliedLoyaltyCard
  );
  const loyaltyProgramDetails = useSelector(
    (state) => state.Cart.loyaltyProgramDetails
  );
  const holdData = useSelector((state) => state.Checkout.holdDetails);
  const isReserved = holdData?.amount?.isReserved && true;
  const avoirList = useSelector((state) => state.Checkout.avoirList);
  const customergroupparent = useSelector(
    (state) => state.Configuration.customergroupparent
  );
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const itemgroup = useSelector((state) => state.Configuration.itemgroup);
  const amount = useSelector((state) => state.Checkout.amount);
  const discount = useSelector((state) => state.Checkout.discount);
  const payments = useSelector((state) => state.Checkout.amount.payments);
  const products = useSelector((state) => state.Checkout.products);
  const client = useSelector((state) => state.Checkout.client);
  const clients = useSelector((state) => state.Checkout.clients);
  const selectedCoupon = useSelector((state) => state.Checkout.selectedCoupon);
  const posOpenedDate = useSelector((state) => state.Login.posOpeningDate);
  const cheques = useSelector((state) => state.Checkout.amount.cheques);

  const open = useSelector((state) => state.Pos.open);
  const selectedSalesPerson = useSelector(
    (state) => state.Checkout.selectedSalesPerson
  );
  const listCards = useSelector((state) => state.Client.giftCardCouponCode);
  const pricingRuleClient = useSelector(
    (state) => state.Client.pricingRuleClient
  );
  const detailsCreditNote = useSelector(
    (state) => state.Client.detailCreditNote
  );
  const creditNotesList = useSelector((state) => state.Checkout.creditNotes);
  const selectedGiftCardCoupon = useSelector(
    (state) => state.Checkout.selectedGiftCardCoupon
  );
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const order = useSelector((state) => state.Checkout.order);
  const currencies = useSelector((state) => state.Client.currencies);
  const currencyExchangeRate = useSelector(
    (state) => state.Checkout.currencyExchangeRate
  );
  const exchangeRate = useSelector((state) => state.Checkout.exchangeRate);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const posOpeningEntry = useSelector((state) => state.Pos.open);
  const taxsales = useSelector((state) => state.Checkout.taxsales);
  const [itemPrestationList, setItemPrestationListList] = useState([]);

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user")).token,
  };

  const getItemPrestationsList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "Item",
        fields: ['`tabItem`.`name`', '`tabItem`.`item_code`', '`tabItem`.`prix_de_vente_ht`', '`tabItem`.`standard_rate`'],
        filters: [
          ['Item', 'is_stock_item', '=', 0],
          ['Item', 'item_group', '=', 'Services']
      ],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setItemPrestationListList(data);
        }
      });
  };

  useEffect(() => {
    getItemPrestationsList();
    }
    , []);

  //console.log(itemPrestationList, "itemPrestationList");

  const calculateTaxRate = (item) => {
    const matchingItem = itemPrestationList.find(prestationItem => prestationItem.item_code === item.item_code);
    if (matchingItem) {
      const standardRate = parseFloat(matchingItem.standard_rate);
      const prixDeVenteHT = parseFloat(matchingItem.prix_de_vente_ht);
      if (standardRate && prixDeVenteHT) {
        return ((prixDeVenteHT - standardRate) / standardRate) * 100;
      }
    }
    return 0;
  };

  const calculateTotalTaxAmount = () => {
    return products.reduce((totalTax, product) => {
      const prestationTaxRate = calculateTaxRate(product);
      if (prestationTaxRate > 0) {
        const taxAmount = (product.price_list_rate * product.quantity * prestationTaxRate) / 100;
        return totalTax + taxAmount;
      } else {
        const existingTaxRate = parseFloat(product.item_tax_template?.match(/\d+/g)?.toString().replace(",", ".")) || 0;
        const taxAmount = (product.price_list_rate * product.quantity * existingTaxRate) / 100;
        return totalTax + taxAmount;
      }
    }, 0);
  };

  const calculateTotalWithTax = () => {
    return products.reduce((total, product) => {
      const prestationTaxRate = calculateTaxRate(product);
      let productTotal = product.price_list_rate * product.quantity;
  
      if (prestationTaxRate > 0) {
        const taxAmount = (productTotal * prestationTaxRate) / 100;
        productTotal += taxAmount;
      } else {
        const existingTaxRate = parseFloat(product.item_tax_template?.match(/\d+/g)?.toString().replace(",", ".")) || 0;
        const taxAmount = (productTotal * existingTaxRate) / 100;
        productTotal += taxAmount;
      }
  
      return total + productTotal;
    }, 0);
  };

  const taxsalesvalue =
    taxsales && !isNaN(taxsales?.value) ? parseFloat(taxsales?.value) : 0;
  const totalWithTax = calculateTotalWithTax(products, itemPrestationList);
  const totalTaxAmount = calculateTotalTaxAmount(products, itemPrestationList);
  const totalAmount = (totalWithTax - (discount * totalWithTax) / 100 + taxsalesvalue).toFixed(3);
  const totalAmountPayed = payments
    ? payments.reduce((a, v) => (a = a + parseFloat(v.amount)), 0).toFixed(3)
    : 0;
  const [openChequePayment, setOpenChequePayment] = useState(false);
  const [openCreditPayment, setOpenCreditPayment] = useState(false);
  const [openGiftCardPayment, setOpenGiftCardPayment] = useState(false);
  const [openWarn, setOpenWarn] = useState(false);
  const [currency, setCurrency] = useState(companyacounts?.default_currency);

  const [searchAvoir, setSearchAvoir] = useState("");
  const [openAvoirPayment, setOpenAvoirPayment] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [filteredAvoirList, setFilteredAvoirList] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [opencoffre, setOpencoffre] = useState(false);

  const ignorePricingRule = useSelector(
    (state) => state.Checkout.ignorePricingRules
  );
  const itemDetails = useSelector((state) => state.Checkout.itemDetails);

  const currencySymbol =
    client && client.default_currency
      ? symbol(client.default_currency, currencies)?.symbol
      : currency
      ? symbol(currency, currencies)?.symbol
      : globalDefaults?.default_currency;

  const [selectedValue, setSelectedValue] = useState("cheque");
  const [chequeDate, setChequeDate] = useState(
    formatDate(new Date(), "YYYY-MM-DD")
  );
  const [code, setCode] = useState("");
  const [chequeData, setChequeData] = useState({
    ref: "",
    nom_banque: "",
  });

  const [creditData, setCreditData] = useState({
    number: "",
    name: "",
    expiry_date: "",
    security_code: "",
  });

  const handleChequeDateChange = (date) => {
    setChequeDate(date);
  };

  const handleChangeCode = (e) => {
    setCode(e.target.value);
  };

  const payer = () => {
    dispatch(SetTPEticketNumber(null));
    if (code) {
      const GiftCard =
        Array.isArray(listCards) &&
        listCards.length != 0 &&
        listCards.find(
          (s) =>
            s.valid_upto >= formatDate(new Date(), "YYYY-MM-DD") &&
            s.used == 0 &&
            s.coupon_code == code
        );
      if (GiftCard) {
        dispatch(LoadPricingRuleByName(GiftCard.pricing_rule));
        dispatch(SetGiftCardCouponCode(GiftCard));
        setOpenWarn(true);
      } else {
        dispatch(
          SetNotification({
            code: "error",
            message: "payement:couponExpiréMsg",
          })
        );
      }
    }
  };

  const loadCard = async () => {
    if (selectedGiftCardCoupon && pricingRuleClient) {
      const giftCardBalance = pricingRuleClient.discount_amount;
      const amountToCharge = Math.min(giftCardBalance, amount.totalAmount);
  
      if (amountToCharge > 0) {
        await createOrder();
        await updateGiftCardBalance(giftCardBalance - amountToCharge);
        history.push("/checkout/payement/2");
      } else {
        dispatch(
          SetNotification({
            code: "error",
            message: "payement:insufficientBalance",
          })
        );
      }
    }
  };

    const updateGiftCardBalance = async (newBalance) => {
      const data = {
        name: pricingRuleClient?.name,
        discount_amount: newBalance,
        api: "update-gift-card",
        doctype: "Pricing Rule",
      };
    
      await dispatch(updateClientPricingRule(data));
    
      if (newBalance <= 0) {
        const dataCoupon = {
          name: selectedGiftCardCoupon?.name,
          used: 1,
          api: "update-gift-card",
          doctype: "Coupon Code",
        };
        await dispatch(updateClientPricingRule(dataCoupon));
      }
    
      dispatch(SetGiftCardCouponCode({}));
    };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  const HandleAmount = (price) => {
    const depositAmount = isNaN(price) ? 0.0 : price;
    dispatch(
      UpdatePayment({
        ...amount,
        depositAmount: depositAmount,
      })
    );
  };

  const createOrder = async (addCheques2Coffre) => {
    var items = {};
    var paymentList = [];
    var taxes = [];
    var salesTeam = [];

    // avoir list
    var avoirs = [];
    const avoirPaymentIndex = payments
      ? payments.findIndex((pay) => pay?.name?.toLowerCase() === "avoir")
      : -1;

    if (avoirPaymentIndex !== -1) {
      if (Array.isArray(payments[avoirPaymentIndex]?.avrList)) {
        payments[avoirPaymentIndex]?.avrList?.forEach((el) => {
          avoirs.push(el);
        });
      }
    }

    const totalAmount = amount.totalAmount
      ? (
          amount.totalAmount -
          (discount * amount.totalAmount) / 100 +
          taxsalesvalue
        ).toFixed(3)
      : 0;
    const totalAmountPayed = amount.payments
      ? amount.payments.reduce((a, v) => (a = a + parseFloat(v.amount)), 0)
      : 0;
    const rendu =
      amount.totalAmount && parseFloat(totalAmountPayed - totalAmount) <= 0
        ? 0
        : parseFloat(totalAmountPayed - totalAmount).toFixed(3);
    amount.payments.forEach((element) => {
      const payData = {
        parentfield: "payments",
        parenttype: "POS Invoice",
        amount:
          element.amount - rendu > 0 ? element.amount - rendu : element.amount,
        mode_of_payment: element.mode_of_payment,
        type: element.type,
        doctype: "Sales Invoice Payment",
        __islocal: 1,
        idx: 1,
        account: element?.default_account,
      };
      paymentList.push(payData);
    });
    products.forEach((element, index) => {
      // Check if the current element exists
      const existingItem = itemDetails.find(
        (item) => item.item_code === element.name
      );

      // Process the main item
      const mainItem = {
        doctype: "POS Invoice Item",
        docstatus: 0,
        __islocal: 1,
        __unsaved: 1,
        parenttype: "POS Invoice",
        parentfield: "items",
        qty: element.quantity,
        item_name: element.item_name,
        item_code: element.name,
        item_group: element.item_group,
        discount_percentage: element?.discount,
        discount_amount: existingItem ? existingItem.discount_amount : 0,
        item_tax_template: element.item_tax_template,
      };

      // Add the main item to the items array
      items = { ...items, [`item${index + 1}`]: mainItem };

      // Process free_item_data if it exists
      if (!ignorePricingRule && existingItem && existingItem.free_item_data) {
        existingItem.free_item_data.forEach((freeItem, freeIndex) => {
          const freeItemData = {
            doctype: "POS Invoice Item",
            docstatus: 0,
            __islocal: 1,
            __unsaved: 1,
            parenttype: "POS Invoice",
            parentfield: "items",
            qty: freeItem.qty, // Adjust this based on the free item's quantity
            item_name: freeItem.item_name,
            item_code: freeItem.item_code,
            pricing_rules: freeItem.pricing_rules,
            rate: freeItem.rate,
            is_free_item: freeItem.is_free_item,
            price_list_rate: freeItem.price_list_rate,
          };

          // Add the free item to the items array
          items = {
            ...items,
            [`freeItem${index + 1}-${freeIndex + 1}`]: freeItemData,
          };
        });
      }

      // Handle taxes (unchanged from your original code)
      if (element?.item_tax_template) {
        const tax = {
          account_head: element?.item_tax_template?.substring(
            element?.item_tax_template.indexOf(" ") + 1
          ),
          parentfield: "taxes",
          parenttype: "POS Invoice",
          doctype: "Sales Taxes and Charges",
          charge_type: "On Net Total",
          cost_center: `${companyacounts?.cost_center}`,
          description:
            element?.item_tax_template.slice(
              8,
              element?.item_tax_template.indexOf("%") + 1
            ) +
            " @ " +
            parseFloat(
              element?.item_tax_template
                .match(/\d+/g)
                .toString()
                .replace(",", ".")
            ).toFixed(1),
        };
        taxes = [...taxes, tax];
      }
    });
    const uniqueTaxes = [];
    const unique = taxes.filter((element) => {
      const isDuplicate = uniqueTaxes.includes(element.account_head);
      if (!isDuplicate) {
        uniqueTaxes.push(element.account_head);
        return true;
      }
      return false;
    });
    /*******************RETAIL-341*************************** */
    if (taxsales && JSON.stringify(taxsales) !== "{}") {
      unique.push({
        account_head: taxsales?.name,
        charge_type: "Actual",
        cost_center: `${process.env.REACT_APP_COST_CENTER}`,
        description: taxsales?.account_name,
        doctype: "Sales Taxes and Charges",
        parentfield: "taxes",
        parenttype: "POS Invoice",
        tax_amount: taxsalesvalue,
      });
    }
    const selectedSalesPersonNames = [];

    selectedSalesPerson.length &&
      selectedSalesPerson.map((s) => {
        s.sales_person_name
          ? selectedSalesPersonNames.push(s.sales_person_name)
          : selectedSalesPersonNames.push(s);
      });

    const percentage = (sp) => {
      return (
        +(100 / selectedSalesPersonNames.length) *
        +selectedSalesPersonNames.filter((s, i) => s == sp).length
      );
    };

    const allSalesPerson = [...new Set(selectedSalesPersonNames)];
    const netTotal =
      amount.totalAmount &&
      (
        amount.totalAmount -
        amount.taxAmount -
        (discount * (amount.totalAmount - amount.taxAmount)) / 100
      ).toFixed(3);
    allSalesPerson.forEach((sp, index) => {
      const salesPerson = {
        allocated_amount: (
          (Math.round(percentage(sp)) / 100) *
          netTotal
        ).toFixed(3),
        allocated_percentage: Math.round(percentage(sp)),
        parentfield: "sales_team",
        parenttype: "POS Invoice",
        sales_person: sp,
        __unsaved: 1,
      };
      salesTeam.push(salesPerson);
    });

    const ttlPerc =
      salesTeam.length != 0
        ? salesTeam.reduce(
            (a, v) => (a = a + parseFloat(v.allocated_percentage)),
            0
          )
        : 100;

    if (ttlPerc != 100 && salesTeam.length != 0) {
      salesTeam.length != 0 &&
        (salesTeam[0].allocated_percentage =
          salesTeam[0].allocated_percentage + (100 - ttlPerc));
    }
    let posData = {
      taxes: unique,
      custom_ref: `POS-INV-REF-${Date.now()}`,
      customer: client ? client.customer_name : "",
      contact_email: client ? client.email_id : "",
      contact_mobile: client ? client.mobile_no : "",
      items: items,
      additional_discount_percentage: parseFloat(discount),
      pos_profile: open?.pos_profile,
      payments: paymentList,
      change_amount: rendu,
      coupon_code: selectedCoupon ? selectedCoupon.name : null,
      ignore_pricing_rule: !ignorePricingRule || selectedCoupon?.name ? 0 : 1,
      transaction_date: formatDate(posOpenedDate, "YYYY-MM-DD"),
      delivery_date: formatDate(posOpenedDate, "YYYY-MM-DD"),
      posting_date: formatDate(posOpenedDate, "YYYY-MM-DD"),
      sales_team: salesTeam,
      paid_amount: parseFloat(totalAmount),
    };

    if (
      appliedLoyaltyCard &&
      appliedLoyaltyCard.redeem_points_amount &&
      amount?.isRedeem
    ) {
      posData = {
        ...posData,
        paid_amount:
          parseFloat(totalAmount) + appliedLoyaltyCard?.redeem_points_amount,
        loyalty_points: parseInt(
          appliedLoyaltyCard?.redeem_points_amount /
            loyaltyProgramDetails?.conversion_factor
        ),
        loyalty_amount: appliedLoyaltyCard?.redeem_points_amount,
        redeem_loyalty_points: 1,
        loyalty_program: appliedLoyaltyCard?.loyalty_program,
        loyalty_redemption_account: loyaltyProgramDetails?.expense_account,
        loyalty_redemption_cost_center: loyaltyProgramDetails?.cost_center,
      };
    }

    const chequeData = {
      cheques: cheques,
      cashier: selectedData.cashier,
      warehouse: selectedData.store,
      opening_entry: posOpeningEntry.name,
      creation_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      addCheques2Coffre: addCheques2Coffre ? true : false,
    };

    const orderData = {
      data: posData,
      default_company: globalDefaults?.default_company,
      default_currency: companyacounts?.default_currency,
      customerGroupParent: customergroupparent,
      itemGroup: itemgroup,
      chequeData: chequeData,
    };

    await dispatch(CreateOrder(orderData, holdData));
    avoirs?.length !== 0 && (await dispatch(cancelPaymentEntry(avoirs)));
    // await dispatch(SetCouponCode({}));
    await dispatch(SetGiftCardCouponCode({}));
    await dispatch(SetCustomRef(orderData.custom_ref));
  };

  const HandleCreateCashOrder = () => {
    createOrder();
    history.push("/checkout/payement/2");
  };

  const HandleUpdateCard = () => {
    const data = {
      name: pricingRuleClient?.name,
      discount_amount: pricingRuleClient?.discount_amount - totalAmount,
      api: "update-gift-card",
      doctype: "Pricing Rule",
    };
    const dataCoupon = {
      name: selectedGiftCardCoupon?.name,
      used: 1,
      api: "update-gift-card",
      doctype: "Coupon Code",
    };
    dispatch(updateClientPricingRule(data));
    dispatch(updateClientPricingRule(dataCoupon));
    dispatch(SetGiftCardCouponCode({}));
  };

  const loadCreditNote = () => {
    if (
      Math.abs(detailsCreditNote.amount) < amount.totalAmount &&
      Math.abs(detailsCreditNote.amount) < amount.depositAmount
    ) {
      history.push("/checkout/payement/1");
    } else {
      if (totalAmountPayed == totalAmount) {
        createOrder();
        history.push("/checkout/payement/2");
      } else {
        dispatch(
          SetNotification({
            code: "error",
            message: "notification:notEqAvoir",
          })
        );
      }
    }
  };

  const handleChangeSearchAvoir = (event) => {
    setSearchAvoir(event.target.value);
    Array.isArray(avoirList) &&
      avoirList.length != 0 &&
      setFilteredAvoirList(
        GlobalfilterData(avoirList, event.target.value, [
          "name",
          "customer",
          "return_against",
          "creation_date",
        ])
      );
  };

  const allocateAmount = (row) => {
    const paymentMethodIndex = payments
      ? payments.findIndex((pay) => pay?.name?.toLowerCase() === "avoir")
      : -1;
    if (paymentMethodIndex !== -1) {
      // avoir payment exists
      if (!payments[paymentMethodIndex]?.avrList?.includes(row?.name)) {
        payments[paymentMethodIndex].amount =
          parseFloat(row.credit_amount) + payments[paymentMethodIndex].amount;
        payments[paymentMethodIndex]?.avrList.push(row?.name);
        dispatch(
          UpdatePayment({
            ...amount,
            depositAmount: 0,
            payments: payments,
          })
        );
      }
    } else {
      // avoir payment doesnt exist
      dispatch(
        UpdatePayment({
          ...amount,
          depositAmount: 0,
          payments: [
            ...payments,
            {
              ...selectedPayment,
              amount: parseFloat(row.credit_amount),
              avrList: [row.name],
            },
          ],
        })
      );
    }
    setOpenAvoirPayment(false);
    setSearchAvoir("");
  };

  function isUsed(name) {
    const index = payments
      ? payments.findIndex((pay) => pay?.name?.toLowerCase() === "avoir")
      : -1;

    if (index === -1) {
      return false;
    } else if (payments[index]?.avrList?.includes(name)) {
      return true;
    } else {
      return false;
    }
  }

  const HandlePaymentMethod = (p) => {
    const index = payments
      ? payments.findIndex((pay) => pay.name === p.name)
      : -1;
    if (p?.name?.toLowerCase() !== "avoir") {
      if (payments) {
        if (index !== -1) {
          payments[index].amount =
            parseFloat(payments[index].amount) +
            parseFloat(amount.depositAmount);
        } else {
          p.amount = parseFloat(amount.depositAmount);
          payments.push(p);
        }
        dispatch(
          UpdatePayment({
            ...amount,
            depositAmount: 0,
            payments: payments,
          })
        );
      } else {
        dispatch(
          UpdatePayment({
            ...amount,
            depositAmount: 0,
            payments: [p],
          })
        );
      }
      const totalAmountPayed = payments?.reduce(
        (a, v) => (a = a + parseFloat(v.amount)),
        0
      );
      if (totalAmountPayed <= totalAmount) {
        if (p.name?.toUpperCase().includes("Cheque"?.toUpperCase())) {
          setOpenChequePayment(true);
        }
        if (p.name?.toUpperCase() == "Credit Card"?.toUpperCase()) {
          setOpenCreditPayment(true);
        }
        if (p.name?.toUpperCase() == "Gift Card"?.toUpperCase()) {
          setOpenGiftCardPayment(true);
        }
      }
    } else {
      dispatch(fetchAvoirList(globalDefaults?.default_company));
      setOpenAvoirPayment(true);
    }
  };

  const HandleDeletePayment = (p) => {
    dispatch(
      UpdatePayment({
        ...amount,
        depositAmount: 0,
        payments: payments.filter((pay) => pay.name !== p.name),
        cheques: p.name?.toUpperCase().includes("Cheque"?.toUpperCase())
          ? []
          : amount.cheques,
      })
    );
  };

  const handleChangeChequeData = (e) => {
    setChequeData({ ...chequeData, [e.target.name]: e.target.value });
  };

  const handleChangeCreditData = (e) => {
    setCreditData({ ...creditData, [e.target.name]: e.target.value });
  };

  const HandlePayment = (addCheques2Coffre) => {
    createOrder(addCheques2Coffre);
    history.push("/checkout/payement/2");
  };

  const handleRedirect = () => {
    setOpenChequePayment(false);
    setOpenCreditPayment(false);
  };

  const warn = (
    <div className={classes.modalPaper}>
      <Typography align={"center"} color={"primary"}>
      Solde de la carte cadeau: {pricingRuleClient?.discount_amount}
      </Typography>
     <Typography align={"center"} color={"primary"}>
      Montant à facturer: {Math.min(pricingRuleClient?.discount_amount, amount.totalAmount)}
     </Typography>
      <Box mt={3} justifyContent="center" display="flex">
        <Button variant="contained" color="primary" onClick={loadCard}>
          {t("common:continuer")}
        </Button>
        <Button color="primary" onClick={() => setOpenWarn(false)}>
          {t("common:annuler")}
        </Button>
      </Box>
    </div>
  );
  const CodeGiftCard = (
    <div className={classes.paper}>
      <Typography align={"center"} color={"primary"}>
        {t("payement:addCode")}
      </Typography>
      <Box mt={3} justifyContent="center" display="flex">
        <FormControl variant="outlined" className={classes.formControl}>
          <OutlinedInput
            className={classes.itemInput}
            id="outlined-adornment-weight"
            placeholder={t("payement:placeholderCarte")}
            autoComplete="off"
            type="text"
            value={code}
            name="code"
            onChange={handleChangeCode}
            endAdornment={
              <InputAdornment position="end">
                <span className={"icon-scan-code"} />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Box>
      <Box mt={3} justifyContent="flex-end" display="flex">
        <Button
          color="primary"
          onClick={() => {
            setOpenGiftCardPayment(false);
            setCode("");
          }}
        >
          {t("common:annuler")}
        </Button>
        <Button color="primary" variant="contained" onClick={payer}>
          {t("common:payer")}
        </Button>
      </Box>
    </div>
  );

  const avoirPayment = (
    <div className={classes.paper}>
      <Typography
        align={"center"}
        color={"primary"}
        style={{ fontWeight: "bold", fontSize: 20 }}
      >
        Utiliser un avoir pour ce paiement
      </Typography>
      <Typography
        align={"center"}
        color={"primary"}
        style={{ fontWeight: "thin", fontSize: 12 }}
      >
        en cliquant sur Utiliser
      </Typography>
      <Box mt={3} style={{ display: "flex", flexDirection: "column", gap: 15 }}>
        <FormControl variant="outlined" size="small">
          <InputLabel id="demo-simple-select-outlined-label">
            ID de l'avoir
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-weight"
            placeholder="CUS-AVR-YYYY-XXXXX"
            autoComplete="off"
            type="text"
            label="ID de l'avoir"
            value={searchAvoir}
            name="searchAvoir"
            onChange={handleChangeSearchAvoir}
            endAdornment={
              <InputAdornment position="end">
                <span className={"icon-scan-code"} />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </FormControl>

        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={tableClasses.tableRow}>
              <TableCell className={tableClasses.tablecellHeader}>
                Ref.
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader}>
                {t("checkout:RetourSur")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader}>
                {t("common:client")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader}>
                {t("common:MontTotal")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader}>
                {t("common:dateAchat")}
              </TableCell>
              <TableCell
                className={tableClasses.tablecellHeader}
                align="right"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{ display: "block", height: 200, overflow: "auto" }}
          >
            {searchAvoir == ""
              ? avoirList &&
                Array.isArray(avoirList) &&
                avoirList.length !== 0 &&
                avoirList.map((row, i) => (
                  <TableRow className={tableClasses.tableRow} key={i}>
                    <TableCell className={tableClasses.tableCell}>
                      {row.name ?? "-"}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell}>
                      {row.return_against ?? "-"}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell}>
                      {row.customer ?? "-"}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell}>
                      {row.credit_amount?.toFixed(3) ?? "-"} {row.currency}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell}>
                      {row.creation_date ?? "-"}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell} align="right">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => allocateAmount(row)}
                        disabled={isUsed(row?.name)}
                      >
                        {t("common:use")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : filteredAvoirList &&
                Array.isArray(filteredAvoirList) &&
                filteredAvoirList.length !== 0 &&
                filteredAvoirList.map((row, i) => (
                  <TableRow className={tableClasses.tableRow} key={i}>
                    <TableCell className={tableClasses.tableCell}>
                      {row.name ?? "-"}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell}>
                      {row.return_against ?? "-"}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell}>
                      {row.customer ?? "-"}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell}>
                      {row.credit_amount?.toFixed(3) ?? "-"} {row.currency}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell}>
                      {row.creation_date ?? "-"} {row.currency}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell} align="right">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => allocateAmount(row)}
                        disabled={isUsed(row.name)}
                      >
                        {t("common:use")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            setOpenAvoirPayment(false);
          }}
        >
          {t("common:annuler")}
        </Button>
      </Box>
    </div>
  );

  const handleClickPayementsMethods = (p) => {
    setSelectedPayment(p);
    HandlePaymentMethod(p);
  };

  const toBePaid = () => {
    if (totalAmount && parseFloat(totalAmount - totalAmountPayed) > 0) {
      return parseFloat(totalAmount - totalAmountPayed).toFixed(3);
    }
    let zero = 0;
    return zero.toFixed(3);
  };

  useEffect(() => {
    if (
      appliedLoyaltyCard &&
      appliedLoyaltyCard?.redeem_points_amount &&
      !amount?.isRedeem
    ) {
      if (amount.totalAmount > appliedLoyaltyCard?.redeem_points_amount) {
        dispatch(
          UpdatePayment({
            ...amount,
            totalAmount:
              amount.totalAmount - appliedLoyaltyCard?.redeem_points_amount,
            isRedeem: true,
          })
        );
      }
    }
  }, [appliedLoyaltyCard]);

  const goBack = () => {
    isReserved && talonProps.CancelCheckoutData();
    history.goBack();
  };
  return (
    <Box className={classes.block}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.titleblock}
      >
        <ArrowBackIcon
          style={{ position: "initial" }}
          onClick={goBack}
          className={classes.backicon}
        />
        <Typography
          align={"center"}
          className={classes.title}
          color={"primary"}
          variant="h5"
        >
          {t("common:Encaissement")}
        </Typography>
        <Box>
          <Hold has2Redirect={true} title="Réserver" />
        </Box>
      </Box>

      <Box className={classes.priceblock}>
      <Typography
  align={"center"}
  color={"primary"}
  className={classes.totalLight}
  style={{ fontWeight: "bold" }}
>
  {t("common:total")}:
  <span>
    {" "}
    {appliedLoyaltyCard?.redeem_points_amount &&
    totalWithTax > appliedLoyaltyCard?.redeem_points_amount
      ? (
          parseFloat(totalAmount) +
          parseFloat(appliedLoyaltyCard?.redeem_points_amount)
        ).toFixed(3)
      : totalAmount}{" "}
    {currencySymbol}
  </span>
</Typography>
        <Typography
          align={"center"}
          color={"primary"}
          className={classes.total}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            justifyContent: "center",
          }}
        >
          Reste à payer:
          <span className={classes.number}>
            {toBePaid()} {currencySymbol}
          </span>
          {appliedLoyaltyCard?.redeem_points_amount &&
          appliedLoyaltyCard?.redeem_points_amount !== 0 ? (
            <Chip
              style={{
                backgroundColor: "#4caf50",
                color: "white",
              }}
              label={
                <>
                  Loyalty:{" "}
                  <span>
                    {appliedLoyaltyCard?.redeem_points_amount} {currencySymbol}
                  </span>
                </>
              }
            />
          ) : null}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        <Grid item xs={12} md={6}>
          <Box className={clsx("freebutton", classes.buttonRoot)}>
            {props.payementsMethods.map((p) => (
              <Button
                key={p?.name}
                disabled={
                  amount.depositAmount == 0 &&
                  p?.name?.toLowerCase() !== "avoir"
                }
                onClick={() => handleClickPayementsMethods(p)}
                className={classes.button}
                variant="contained"
                color="primary"
              >
                <PaymentIcon className={classes.icon} />
                <span>{p?.name?.toUpperCase()}</span>
              </Button>
            ))}
          </Box>
          {payments
            ? payments.map((p) => (
                <Deposit
                  HandleDeletePayment={HandleDeletePayment}
                  payments={p}
                  isReserved
                />
              ))
            : null}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.calculator}>
            <Calculator
              currency={currency}
              isCalcul={false}
              rest={totalAmount - totalAmountPayed}
              amount={amount.depositAmount}
              HandleAmount={HandleAmount}
            />
          </Box>
        </Grid>
        <Box
          style={{
            padding: 20,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            disabled={
              payments?.reduce((a, v) => (a = a + parseFloat(v?.amount)), 0) <
              totalAmount
            }
            onClick={() =>
              cheques && cheques?.length > 0
                ? setOpencoffre(true)
                : HandlePayment()
            }
            size={"large"}
            color="primary"
            variant="contained"
            style={{ padding: "20px 60px", fontSize: 25 }}
          >
            {t("common:payer")?.toUpperCase()}
          </Button>
        </Box>
        <Modal
          open={openChequePayment}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ClickAwayListener onClickAway={() => setOpenChequePayment(false)}>
            <FormCheque
              handleRedirect={handleRedirect}
              selectedPayment={selectedPayment}
            />
          </ClickAwayListener>
        </Modal>
        <Modal
          open={opencoffre}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <FormCoffre
            HandlePayment={HandlePayment}
            setOpencoffre={setOpencoffre}
          />
        </Modal>
        <Modal
          open={openCreditPayment}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ClickAwayListener onClickAway={() => setOpenCreditPayment(false)}>
            <FormTpe handleRedirect={handleRedirect} />
          </ClickAwayListener>
        </Modal>
        <Modal
          open={openGiftCardPayment}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {CodeGiftCard}
        </Modal>
        <Modal
          open={openWarn}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {warn}
        </Modal>
        <Modal
          open={openAvoirPayment}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {avoirPayment}
        </Modal>
        <AlertModal
          setOpenModal={setOpenAlert}
          openModal={openAlert}
          handleCancel={HandleCreateCashOrder}
          title={t("payement:alertPayment")}
        />
      </Box>
    </Box>
  );
};
