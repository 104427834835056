import React from "react";
import { TableRow, TableCell, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const FreeItemTableBody = ({
  products,
  classes,
  currencySymbol,
  ignorePricingRule,
  itemPrestationList,
  isPricingRule,
}) => {
  const { t } = useTranslation(["payement", "common"]);
  const selectCurrency = useSelector((state) => state.Checkout.selectCurrency);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const itemTaxList = useSelector((state) => state.Product.itemTax);

  const calculateTaxRate = (item) => {
    const matchingItem = itemPrestationList.find(prestationItem => prestationItem.item_code === item.item_code);
    if (matchingItem) {
      const standardRate = parseFloat(matchingItem.standard_rate);
      const prixDeVenteHT = parseFloat(matchingItem.prix_de_vente_ht);
      if (standardRate && prixDeVenteHT) {
        return ((prixDeVenteHT - standardRate) / standardRate) * 100;
      }
    }
    return 0;
  };

   const tvaTaxes = (product) => {
    const prestationTaxRate = calculateTaxRate(product);
    let taxRate;
    let taxAmount;

    if (prestationTaxRate > 0) {
      taxRate = prestationTaxRate;
      taxAmount = (product.net_amount * taxRate) / 100;
    } else {
      taxRate = parseFloat(product.item_tax_template?.match(/\d+/g)?.toString().replace(",", ".")) || 0;
      taxAmount = (product.net_amount * taxRate) / 100;
    }

    const formattedTaxRate = `${taxRate.toFixed(2)}%`;
    const formattedTaxAmount = taxAmount.toFixed(3);

    return `${formattedTaxRate}: ${formattedTaxAmount} ${currencySymbol}`;
  };

  const prixTTC = (element) => {
    const prestationTaxRate = calculateTaxRate(element);
    let basePrice = element.price_list_rate || element.standard_rate || element.base_rate;
    
    if (prestationTaxRate > 0) {
      return parseFloat(basePrice) + (parseFloat(basePrice) * prestationTaxRate) / 100;
    } else {
      let taxRate = itemTaxList.find(
        (s) => s.name == element.item_tax_template
      )?.tax_rate || 0;
      return parseFloat(basePrice) + (parseFloat(basePrice) * parseFloat(taxRate)) / 100;
    }
  };

  const discountAmount = (element) => {
    return (element.price_list_rate * element.discount) / 100;
  };

  const discountPercentage = (element) => {
    return (element.discount_amount * 100) / element.price_list_rate;
  };

  const processPricingRules = (pricingRules) => {
    if (typeof pricingRules === "string") {
      try {
        const parsedValue = JSON.parse(pricingRules);
        if (Array.isArray(parsedValue)) {
          return parsedValue;
        } else {
          return "Not an array";
        }
      } catch (error) {
        return pricingRules;
      }
    } else {
      return "Not a string";
    }
  };

  return (
    <>
      {products?.map((product) => (
        <TableRow key={product?.item_name} className={classes.tableRow}>
          <TableCell className={classes.tableCell}>
            <Box
              fontWeight={"bold"}
              fontSize={"13px"}
              fontFamily={"Serif"}
               color={"#000000"}
            >
              {product?.quantity || product?.qty}X {product?.item_name}{" "} <br/>
              <Box   
              fontSize={"11px"}
              fontFamily={"Serif"}
               color={"#000000"} > {product?.item_code}{" "}</Box>
              {isPricingRule
                ? "(" + (processPricingRules(product?.pricing_rules) || "") + ")"
                : null}
            </Box>
            <Box fontFamily={"Courier Prime"} fontSize={"12px"} color={"#000000"}>
              P.U: {prixTTC(product)?.toFixed(3)} {currencySymbol}
            </Box>
            {product.discount ? (
              <Box
                fontSize={"12px"}
                fontFamily={"Courier Prime"}
                 color={"#000000"}
              >
                {t("common:remise")}: {product.discount}% (
                {discountAmount(product)?.toFixed(3)} {currencySymbol})
              </Box>
            ) : null}

            {!ignorePricingRule && product.discount_amount ? (
              <Box
                fontSize={"12px"}
                fontFamily={"Courier Prime"}
                 color={"#000000"}
              >
                {t("common:remise")}: {discountPercentage(product).toFixed(3)}%
                ( {product.discount_amount.toFixed(3)} {currencySymbol})
              </Box>
            ) : null}
            <Box
              fontSize={"12px"}
              fontFamily={"Courier Prime"}
               color={"#000000"}
            >
              {tvaTaxes(product)}
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Box
              align={"right"}
              bottom={"0"}
              fontFamily={"Courier Prime"}
               color={"#000000"}
            >
              {(
                parseFloat(product.qty || product.quantity) * prixTTC(product)
              ).toFixed(3)}{" "}
              {currencySymbol}
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
