import { makeStyles } from "@material-ui/core/styles";

export const useStylesTicket = makeStyles((theme) => ({
  ticketBlock: {
    backgroundColor: "#ffffff",
    textAlign: "center",
    width: "100%",
      boxShadow: "0px 5px 15px 4px #0000000F",
    [theme.breakpoints.up("md")]: {
      minWidth: 420,
    },
    
    fontFamily: "Serif",
    color: "#000000",
    fontSize: 20,
     
    lineHeight: 1,
    borderRadius: 15,
  },
  ticketHeader: {
    color: "#000000",
    textAlign: "center",
    fontWeight: "bold",
  },
  ticketProducts: {
    fontWeight: "bold",
    fontSize: 50,
    color: "#000000",
 
  },
  table: {
    
    color: "#000000",
    "& .MuiTableRow-root": {
      display: "column",
      width: "100%",
      tableLayout: "fixed",
      
       
    },
  },
  ticketFooter: {
    color: "#000000",
    marginTop: "2.375rem",
    fontSize: 14,
    fontWeight: "bold",
    padding: "5px 15px 5px 15px",
  },
  ticket: {
    margin: 15,
    position: "relative",
    padding: "5px 5px 32px 5px",
    borderRadius: 20,
    
    background: "#000000",
    "&:after": {
      background: `linear-gradient(-45deg, #ffffff 16px, transparent 0), 
                    linear-gradient(45deg, #ffffff 16px, transparent 0)`,
      backgroundRepeat: "repeat-x",
      backgroundSize: "32px 32px",
      content: '""',
      display: "flex",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "50px",
    },
  },
  tableBody: {
    color: "#000000",
    display: "block",
    height: 200,
    overflow: "auto",
  },
  tableRow: {
    color: "#000000",
    height: 20,
    "& .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    "& .MuiTableCell-root:last-child": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  tableCell: {
    padding: "7px 8px",
    fontFamily: "Serif",
    fontWeight: "bold",
    fontSize: 11,
    color: "#000000",
    
    
  },
}))